import { Button, TextField } from '@material-ui/core'
import { Align } from '../../../../../common/components/Align'
import { Space } from '../../../../../common/components/Space'
import { VideoUrlInput } from '../../../../../common/components/VideoUrlInput'
import { useCourseContext } from '../../context'

export const VideoDetail: React.FC = () => {
  const {
    selectedVideo,
    updateVideoProperty,
    saveVideo,
    saveEnabled,
    videoUrlValidationResults,
  } = useCourseContext()

  return (
    <>
      <Space size={2} />
      <TextField
        variant='outlined'
        value={selectedVideo?.order}
        label='Sira'
        fullWidth
        type='number'
        size='small'
        onChange={({ target }) =>
          updateVideoProperty('order', parseInt(target.value))
        }
      />
      <Space size={4} />
      <TextField
        variant='outlined'
        value={selectedVideo?.title}
        label='Baslik'
        fullWidth
        size='small'
        onChange={({ target }) => updateVideoProperty('title', target.value)}
      />
      <Space size={4} />
      <TextField
        variant='outlined'
        value={selectedVideo?.description}
        label='Aciklama'
        multiline
        rows={2}
        fullWidth
        size='small'
        onChange={({ target }) =>
          updateVideoProperty('description', target.value)
        }
      />
      <Space size={4} />
      <VideoUrlInput
        value={selectedVideo?.videoUrl}
        setValue={v => updateVideoProperty('videoUrl', v)}
        label='Video Url'
        error={videoUrlValidationResults['videoUrl']}
      />

      <Space size={4} />
      <Align justify='flex-end' fullWidth>
        <Button
          variant='contained'
          color='primary'
          style={{ color: '#eee' }}
          onClick={saveVideo}
          disabled={!saveEnabled}>
          Kaydet
        </Button>
      </Align>
    </>
  )
}
