import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router'
import { Box, Typography } from '@material-ui/core'

import useStyles from './styles'
import { Page } from '../../common/components/Page'
import { AppLayout } from '../../common/components/Layout'
import { VideoWithQuestions } from './VideoWithQuestions/VideoWithQuestions'
import { LoadingSpinner } from '../../common/components/LoadingSpinner/Loading'
import { useAuth } from '../../common/contexts/auth'
import { VideoPlayer } from './VideoPlayer/VideoPlayer'
import { VideoSectionContextProvider } from './context/VideoSectionContext'
import { VideoProgress, Video as IVideo, Progress } from '../../types/domain'
import { useGetRequest } from '../../common/useGetRequest'
import { makeRequest } from '../../common/makeRequest'
import { VideoCarousel } from '../../common/components/VideoCarousel/VideoCarousel'
import {
  getCarouselVideos,
  getVideoIdUserCanWatch,
  getVideoIdUserIsAt,
} from '../video-helpers'
import { initializeGA } from '../../utils/googleAnalytics'
import { SectionEnd } from './SectionEnd/SectionEnd'
import { useNotification } from '../../common/contexts/notification'

export const VideoPage = () => {
  const classes = useStyles()
  const { getIsLoggedIn } = useAuth()
  const { setNotification } = useNotification()
  const history = useHistory()

  const [isVideoEnded, setIsVideoEnded] = useState(false)

  const { id: videoId } = useParams<{ id: string }>()

  const isLoggedIn = getIsLoggedIn()

  const [isVideoProgressLoading, setIsVideoProgressLoading] = useState(true)
  const [videoProgress, setVideoProgress] = useState<VideoProgress[]>([])
  const [isWatchedMap, setIsWatchedMap] = useState<Map<string, boolean>>(
    new Map()
  )

  const { loading, data } = useGetRequest<{
    videos: IVideo[]
  }>(`/videos`, {
    videos: [],
  })

  const getVideo = () => data?.videos.find(video => video.id === videoId)
  const video = getVideo()

  const videoHasQuestions = !!video?.questions?.length

  const getCarouselVideoIdToVisit = (carouselVideoId: string) => {
    const videoIdUserIsAt = getVideoIdUserIsAt(data.videos, videoProgress)

    return getVideoIdUserCanWatch({
      videoId: carouselVideoId,
      videos: data.videos,
      videoIdUserIsAt,
      isLoggedIn,
      isWatchedMap,
    })
  }

  const onVideoCarouselClick = (id: string) => {
    const carouselVideoId = getCarouselVideoIdToVisit(id)

    if (carouselVideoId && carouselVideoId !== video.id) {
      history.push({
        pathname: `/video/${carouselVideoId}`,
      })
    }
  }

  const completeVideo = () => {
    if (!isLoggedIn) {
      setIsVideoEnded(true)
      return
    }

    makeRequest({
      url: `/videos/${videoId}/complete`,
      method: 'POST',
      onError: () => {
        setNotification({
          type: 'danger',
          title: 'Videoyu kaydederken hata oluştu.',
        })
      },
      onSuccess: () => {
        setIsVideoEnded(true)
      },
    })
  }

  const getNextVideoId = () => {
    const currentVideoIdx = data.videos.findIndex(v => v.id === video.id)
    return data.videos[currentVideoIdx + 1]?.id || data.videos[0].id
  }

  const onGoNextClick = () => {
    history.push({
      pathname: `/video/${getNextVideoId()}`,
    })
  }

  useEffect(() => {
    const fetchProgress = () => {
      makeRequest<Progress>({
        url: '/my-progress',
        method: 'GET',
        onSuccess: data => {
          setVideoProgress(data.progress)
          setIsVideoProgressLoading(false)
          setIsWatchedMap(
            data.progress.reduce((m, p) => {
              m.set(p.id, !!p.completedAt)
              return m
            }, new Map())
          )
        },
        onError: () => {
          setIsVideoProgressLoading(false)
        },
      })
    }

    isLoggedIn && fetchProgress()
  }, [isLoggedIn, videoId])

  useEffect(() => {
    setIsVideoEnded(false)
  }, [videoId])

  useEffect(() => {
    initializeGA(`Video-${videoId}`)
  }, [videoId])

  const renderVideoInfo = () => (
    <Box className={classes.titleBox}>
      <Typography variant='h3' className={classes.title}>
        {video.title}
      </Typography>
      <Typography>{video.description}</Typography>
    </Box>
  )

  return (
    <Page>
      <AppLayout pb={'70'}>
        {loading && <LoadingSpinner />}
        {isLoggedIn && video && !isVideoProgressLoading && videoHasQuestions && (
          <VideoSectionContextProvider
            video={video}
            videoProgress={videoProgress}>
            {renderVideoInfo()}
            <VideoWithQuestions
              nextVideoId={getNextVideoId()}
              videos={data.videos}
              video={video}
              videoProgress={videoProgress}
              isVideoEnded={isVideoEnded}
              setIsVideoEnded={setIsVideoEnded}
              onVideoEnd={completeVideo}
              videoIdUserIsAt={getVideoIdUserIsAt(data.videos, videoProgress)}
              onVideoCarouselClick={onVideoCarouselClick}
              getCanWatch={id => Boolean(getCarouselVideoIdToVisit(id))}
              isWatchedMap={isWatchedMap}
            />
          </VideoSectionContextProvider>
        )}

        {(!isLoggedIn || (isLoggedIn && !videoHasQuestions)) && video && (
          <Box width='100%'>
            {renderVideoInfo()}
            {!isVideoEnded ? (
              <VideoPlayer url={video.videoUrl} onEnded={completeVideo} />
            ) : (
              <SectionEnd
                section='video'
                videoHasQuestions={false}
                sectionEndText='Videoyu izledin.'
                onGoNextClick={onGoNextClick}
                onWatchVideoAgainClick={() => setIsVideoEnded(false)}
              />
            )}
            <Box className={classes.carouselWrapper}>
              <VideoCarousel
                videos={getCarouselVideos(data.videos, videoId, isLoggedIn)}
                handleClick={onVideoCarouselClick}
                getCanWatch={id => Boolean(getCarouselVideoIdToVisit(id))}
                videoIdUserIsAt={getVideoIdUserIsAt(data.videos, videoProgress)}
                isWatchedMap={isWatchedMap}
              />
            </Box>
          </Box>
        )}
      </AppLayout>
    </Page>
  )
}
