import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles<Theme, { variant?: string }>(
  ({ palette, spacing }) => {
    const variants = {
      primary: {
        background: palette.primary.main,
        color: palette.white.main,
        border: palette.primary.main,
      },
      secondary: {
        background: palette.primary.light,
        color: palette.primary.main,
        border: palette.primary.light,
      },
      tertiary: {
        background: 'transparent',
        color: palette.primary.main,
        border: palette.primary.main,
      },
      hover: {
        primary: {
          background: 'transparent',
          color: palette.primary.main,
          border: palette.primary.main,
        },
        secondary: {
          background: palette.primary.light,
          color: palette.primary.main,
          border: palette.primary.light,
        },
        tertiary: {
          background: palette.primary.main,
          color: palette.white.main,
          border: palette.primary.main,
        },
      },
    }
    // -- End of variants
    return createStyles({
      btn: {
        textTransform: 'none',
        border: ({ variant }) => `1px solid ${variants[variant].border}`,
        background: ({ variant }) => variants[variant].background,
        color: ({ variant }) => variants[variant].color,
        borderRadius: 5,
        padding: spacing(0.6, 3),

        '&:hover': {
          background: ({ variant }) => variants.hover[variant].background,
          color: ({ variant }) => variants.hover[variant].color,
          border: ({ variant }) => `1px solid ${variants[variant].border}`,
        },
        '&:disabled': {
          color: 'rgba(0, 0, 0, 0.26)',
          border: `1px solid ${palette.gray.light}`,
          background: palette.gray.light,
        },
      },
    })
  }
)
