import { Button, Paper } from '@material-ui/core'
import { FC } from 'react'
import { AdminPage } from '../../../common/components/AdminPage'
import { Align } from '../../../common/components/Align'
import { Table, TableHeader } from '../../../common/components/Table'
import { SelectedVideo } from './components/SelectedVideo'
import { CourseProvider, useCourseContext } from './context'
import { VideoTableRow } from './components/VideoTableRow'

const tableHeaders: TableHeader[] = [{ label: 'Sira' }, { label: 'Baslik' }]

const CourseC: FC<{}> = () => {
  const { loading, course, selectedVideo, setSelectedVideo, handleAddVideoClick } = useCourseContext()
  const detailOpen = !!selectedVideo

  const topBar = (
    <Align fullWidth justify='flex-end' margin={[-0.5, 0, 1, 0]}>
      <Button variant='contained' color='primary' onClick={handleAddVideoClick} disabled={!!selectedVideo?.isNew}>
        Video Ekle
      </Button>
    </Align>
  )

  const mainContent = (
    <Paper style={{ width: '100%' }}>
      <Table
        stickyHeader
        rows={course.videos.map(v => (
          <VideoTableRow key={v.id} video={v} selectedVideo={selectedVideo} setSelectedVideo={setSelectedVideo} />
        ))}
        headers={tableHeaders}
      />
    </Paper>
  )

  return (
    <AdminPage
      isLoading={loading}
      topBar={topBar}
      mainContent={mainContent}
      detailOpen={detailOpen}
      detailComponent={<SelectedVideo />}
    />
  )
}

export const Course = () => (
  <CourseProvider>
    <CourseC />
  </CourseProvider>
)
