import {
  alpha,
  createStyles,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputBase,
  InputLabel,
  makeStyles,
  OutlinedInput,
} from '@material-ui/core'
import { Field } from 'formik'

interface Props {
  name: string
  label?: string
  type?: string
  placeholder?: string
  fullWidth?: boolean
  autoComplete?: boolean
  icon?: JSX.Element
  iconPosition?: 'start' | 'end'
}

export const Input = ({
  name,
  label,
  placeholder,
  type = 'text',
  fullWidth,
  autoComplete,
  icon,
  iconPosition = 'start',
}: Props) => {
  const classes = useStyles({ fullWidth })

  return (
    <Field name={name}>
      {({ field, meta: { touched, error } }) => (
        <FormControl className={classes.formControl}>
          {label && (
            <InputLabel
              shrink
              htmlFor='input'
              className={classes.label}
              classes={{
                formControl: classes.formLabelRoot,
              }}>
              {label}
            </InputLabel>
          )}

          {icon ? (
            <OutlinedInput
              {...field}
              classes={{
                input: classes.outlinedInput,
                root: classes.outlinedRoot,
              }}
              type={type}
              fullWidth={fullWidth}
              autoComplete={typeof autoComplete !== undefined && !autoComplete ? 'new-password' : null}
              error={touched && !!error}
              {...field}
              startAdornment={
                iconPosition === 'start' && <InputAdornment position={iconPosition}>{icon}</InputAdornment>
              }
              endAdornment={iconPosition === 'end' && <InputAdornment position={iconPosition}>{icon}</InputAdornment>}
            />
          ) : (
            <InputBase
              id={'input-' + label}
              placeholder={placeholder}
              classes={{ input: classes.input }}
              type={type}
              fullWidth={fullWidth}
              autoComplete={typeof autoComplete !== undefined && !autoComplete ? 'new-password' : null}
              error={touched && !!error}
              {...field}
            />
          )}

          {touched && error && <FormHelperText classes={{ root: classes.helperText }}>{error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  )
}

const useStyles = makeStyles(({ spacing, transitions, palette }) =>
  createStyles({
    formLabelRoot: {
      position: 'relative',
      '&+ .MuiInput-formControl': {
        margin: 0,
      },
    },
    formControl: {
      width: ({ fullWidth }: { fullWidth: boolean }) => fullWidth && '100%',
      marginBottom: spacing(3),
    },
    outlinedRoot: {
      backgroundColor: palette.common.white,
    },
    label: {
      fontWeight: 600,
      marginBottom: spacing(1),
    },
    helperText: {
      color: palette.error.main,
    },
    icon: {
      position: 'absolute',
      left: '5px',
      zIndex: 1,
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      border: '1px solid #ced4da',
      backgroundColor: palette.common.white,
      padding: '10px 12px',
      transition: transitions.create(['border-color', 'box-shadow']),

      '&:focus': {
        boxShadow: `${alpha(palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: palette.primary.main,
      },
    },
    outlinedInput: {
      position: 'relative',
      padding: '10px 12px',
    },
  })
)
