import useStyles from './styles'
import { Align } from '../../../common/components/Align'
import { Button } from '../../../common/components/Button'
import { Box, Typography } from '@material-ui/core'
import preEndImg from '../../../assets/alistirma.png'
import videoEndImg from '../../../assets/video.png'
import postEndImg from '../../../assets/anket.png'

interface Props {
  sectionEndText: string
  onGoNextClick: () => void
  onWatchVideoAgainClick?: () => void
  isInitialForm?: boolean
  nextVideoId?: boolean | undefined
  section: 'pre' | 'video' | 'post'
  videoHasQuestions?: boolean
  isLastVideo?: boolean
}

const imgSrc = {
  pre: preEndImg,
  video: videoEndImg,
  post: postEndImg,
}

const postText = 'Sonraki bölüme geç'
const btnText = (videoHasQuestions?: boolean) => ({
  pre: 'Videoya geç',
  video: videoHasQuestions ? 'Alıştırmaya geç' : postText,
  post: postText,
})

export const SectionEnd = ({
  sectionEndText,
  onGoNextClick,
  onWatchVideoAgainClick,
  section,
  videoHasQuestions,
  isLastVideo = false,
}: Props) => {
  const classes = useStyles()

  return (
    <Align
      vertical
      justify='center'
      align='center'
      className={classes.sectionContainer}>
      <img
        className={classes.completedImg}
        src={imgSrc[section]}
        alt='Completed section screen'
      />
      <Typography style={{ paddingBottom: 8 }}>{sectionEndText}</Typography>
      <Box display='flex' justifyContent='center'>
        {onWatchVideoAgainClick && (
          <Button
            style={{ marginTop: 20, marginRight: 10 }}
            text='Videoyu tekrar izle'
            onClick={onWatchVideoAgainClick}
          />
        )}
        {!(isLastVideo && section === 'post') && (
          <Button
            style={{ marginTop: 20 }}
            text={btnText(videoHasQuestions)[section]}
            onClick={onGoNextClick}
          />
        )}
      </Box>
    </Align>
  )
}
