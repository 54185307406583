import { Box, Checkbox, Typography } from '@material-ui/core'

import { Align } from '../../../common/components/Align'
import { Choice } from '../../../types/domain'
import { VideoPlayer } from '../VideoPlayer/VideoPlayer'

import useStyles from './styles'

interface Props {
  choice: Choice
  selectedChoice: string
  userAnswer: string
  correctAnswer: string
  setSelectedChoice: (label: string) => void
  isInitialForm: boolean
}

export const QuestionChoice = ({
  choice,
  selectedChoice,
  setSelectedChoice,
  userAnswer,
  correctAnswer,
  isInitialForm = false,
}: Props) => {
  const isCorrectAnswer = choice.label === correctAnswer
  const isWrongAnswer = choice.label === userAnswer
  const isSelectedChoice = choice.label === selectedChoice

  const classes = useStyles({
    correctAnswer: isCorrectAnswer,
    wrongAnswer: isWrongAnswer,
  })

  return (
    <Box>
      <Typography variant='body2' style={{ marginBottom: 12 }}>
        <Box component='span' className={classes.label}>
          {choice.label}
          {')'}
        </Box>
        {choice.text}
      </Typography>
      <Align align='flex-start'>
        <Checkbox
          inputProps={{ 'aria-label': 'primary checkbox' }}
          className={classes.checkbox}
          classes={{ root: classes.root, checked: classes.checked }}
          checked={isSelectedChoice}
          onChange={e =>
            setSelectedChoice(e.target.checked ? choice.label : null)
          }
        />

        <Box width='100%' position='relative'>
          {!isInitialForm &&
            correctAnswer &&
            (isWrongAnswer || isCorrectAnswer) && (
              <Align
                justify='center'
                align='center'
                className={classes.answerIndicator}>
                <Box component='span'>
                  {isCorrectAnswer ? 'DOĞRU' : 'YANLIŞ'}
                </Box>
              </Align>
            )}
          <VideoPlayer url={choice.videoUrl} />
        </Box>
      </Align>
    </Box>
  )
}
