import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ spacing, palette }) =>
  createStyles({
    root: {
      width: '100%',
      marginLeft: spacing(1),
      overflow: 'auto',
    },
    content: {
      padding: spacing(2),
    },
    title: {
      flexGrow: 1,
      color: palette.secondary.light,
    },
    appBar: {
      padding: spacing(0, 2),
    },
  })
)
