import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { Formik, FormikHelpers } from 'formik'
import { FC } from 'react'
import { Align } from '../../../common/components/Align'
import { Button } from '../../../common/components/Button'
import { Input } from '../../../common/components/Input'
import { useNotification } from '../../../common/contexts/notification'
import { makeRequest } from '../../../common/makeRequest'
import { Progress } from '../../../types/domain'
import useStyles from './styles'

interface Props {
  progress: Progress
}

export const Certification: FC<Props> = ({ progress }) => {
  const { setNotification } = useNotification()
  const classes = useStyles()

  const onSubmit = (payload, formikHelpers: FormikHelpers<any>) => {
    makeRequest({
      url: '/request-certificate',
      method: 'POST',
      payload,
      onSuccess: () => {
        setNotification({
          type: 'success',
          title: 'Tesekkurler',
          content: 'Sertifika talebin alindi',
        })
        formikHelpers.resetForm()
      },
      onError: data => {
        setNotification({
          type: 'danger',
          title: data?.message || 'Üzgünüz, bir hata oluştu.',
        })
      },
    })
  }

  return (
    <Formik initialValues={{ email: '', name: '' }} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Box>
          <Typography variant='h4' color='primary'>
            Katılım belgesi
          </Typography>
          <Paper className={classes.certificatePaper}>
            <Typography>
              Tüm videoları izlediğinde katılım belgesi alabilirsin. Bunun için
              adını, soyadını ve e-posta adresini yaz ve ‘‘katılım belgesi
              istiyorum’’ düğmesine bas. Katılım belgesi e-posta adresine en
              kısa sürede iletilecek.
            </Typography>
            <Grid container className={classes.inputWrapper}>
              <Grid item xs={12} sm={12} lg={6} className={classes.leftCol}>
                <Input name='name' label='Ad ve Soyad' type='text' fullWidth />
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Input
                  name='email'
                  label='E-posta adresi'
                  type='email'
                  fullWidth
                />
              </Grid>
            </Grid>
            <Align justify='flex-end'>
              <Button
                onClick={handleSubmit}
                text='Katılım belgesi istiyorum'
                style={{ marginTop: 20 }}
                // isDisabled={!progress.isDone}
              />
            </Align>
          </Paper>
        </Box>
      )}
    </Formik>
  )
}
