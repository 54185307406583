import { createTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
})

const theme = {
  palette: {
    primary: {
      main: '#4f1d94',
      dark: '#aa459e',
      light: '#e8ccff',
    },
    secondary: { main: '#696969', light: '#eee', dark: '#7e7e7e' },
    gray: { main: '#615f70', light: '#e0e0e0', dark: '#b7b7b7' },
    error: { main: '#ED4337', light: '#e6643c' },
    success: { main: '#21af02' },
    white: { main: '#fff' },
    footer: { main: '#faf8f2', dark: '#484a69' },
  },
  overrides: {
    MuiGrid: {
      'spacing-xs-3': {
        '& > $item': {
          paddingBottom: 5,
        },
      },
    },
    MuiPaper: {
      root: {
        boxShadow: '0 3px 9px 0 rgba(21,27,38,.1)',
      },
    },
    MuiDrawer: {
      paper: {
        color: 'white',
      },
    },
    MuiTypography: {
      h1: {
        fontSize: 32,
        fontWeight: 'bold',
      },
      h2: {
        fontSize: 24,
        fontWeight: 'bold',
      },
      h3: {
        fontSize: 18.72,
        fontWeight: 'bold',
      },
      h4: {
        fontSize: 16,
        fontWeight: 'bold',
      },
      h5: {
        fontSize: 13.28,
        fontWeight: 'bold',
      },
      h6: {
        fontSize: 10.72,
        fontWeight: 'bold',
      },
      body1: {
        fontSize: 16,

        [breakpoints.down('xs')]: {
          fontSize: 14,
        },
      },
      body2: {
        fontSize: 14,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
      },
    },
    MuiPopover: {
      paper: {
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
      },
    },
    MuiToolbar: {
      gutters: {
        padding: '0 !important',
      },
    },
  },
  breakpoints,
}

export default createTheme(theme as any)
