import { FC, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import useStyles from './styles'
import { Align } from '../../../common/components/Align'
import { DropdownOption } from '../../../common/components/Dropdown'
import { useAuth } from '../../../common/contexts/auth'
import { useNotification } from '../../../common/contexts/notification'
import { PasswordResetForm } from '../PasswordResetForm/PasswordResetForm'
import { PersonalInfoForm } from '../PersonalInfoForm/PersonalInfoForm'

export const ProfileSettings: FC<{}> = () => {
  const { userData, updatePassword, updatePersonalInfo } = useAuth()
  const [expanded, setExpanded] = useState({ profile: true, password: false })
  const { setNotification } = useNotification()
  const classes = useStyles()

  const handleSubmitPersonalInfo = async ({
    ageRange,
    definitions,
    communicationPreference,
    phoneNumber,
  }: {
    ageRange: string
    communicationPreference: DropdownOption[]
    definitions: DropdownOption[]
    phoneNumber: string
  }) => {
    const [, error] = await updatePersonalInfo({
      ...userData,
      ageRange,
      definitions: definitions.map(d => d.value),
      communicationPreference: communicationPreference.map(p => p.value),
      phoneNumber,
    })
    if (error) {
      setNotification({
        type: 'danger',
        title: error?.message || 'Üzgünüz, bir hata oluştu',
      })
    } else {
      setNotification({ type: 'success', title: 'Bilgilerini güncelledik.' })
    }
  }

  const handleSubmitPasswordReset = async ({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string
    newPassword: string
    newPasswordRepeated: string
  }) => {
    const [, error] = await updatePassword({
      oldPassword,
      newPassword,
    })
    if (error) {
      setNotification({
        type: 'danger',
        title: error?.data?.message || 'Uzgunuz bir hata olustu',
      })
      return false
    }
    setNotification({ type: 'success', title: 'Bilgilerini güncelledik.' })
    return true
  }

  return (
    <Box>
      <Typography variant='h4' color='primary'>
        Profil ayarları
      </Typography>
      <Accordion
        expanded={expanded['profile']}
        classes={{ root: classes.accordionRoot }}
        className={classes.accordion}>
        <AccordionSummary
          onClick={() => setExpanded(ee => ({ ...ee, profile: !ee.profile }))}
          expandIcon={<ExpandMore />}
          aria-controls='panel1a-content'
          id='panel1a-header'>
          <Typography>Kişisel Bilgiler</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Align vertical fullWidth padding={[2, 0]}>
            <PersonalInfoForm onSubmit={handleSubmitPersonalInfo} />
          </Align>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded['password']} className={classes.accordion}>
        <AccordionSummary
          onClick={() => setExpanded(ee => ({ ...ee, password: !ee.password }))}
          expandIcon={<ExpandMore />}
          aria-controls='panel1a-content'
          id='panel1a-header'>
          <Typography>Şifre Güncelleme</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Align vertical fullWidth padding={[2, 0]}>
            <PasswordResetForm onSubmit={handleSubmitPasswordReset} />
          </Align>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
