import { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Page } from '../../common/components/Page'
import { useGetRequest } from '../../common/useGetRequest'
import { TopBar } from './TopBar'
import { VideoActions } from './VideoActions'
import { Progress, Video, VideoProgress } from '../../types/domain'
import { LoadingSpinner } from '../../common/components/LoadingSpinner/Loading'
import { makeRequest } from '../../common/makeRequest'
import { useAuth } from '../../common/contexts/auth'
import { NoData } from '../../common/components/NoData'
import { VideosList } from './VideosList'
import { VideoCarousel } from '../../common/components/VideoCarousel/VideoCarousel'
import { Box } from '@material-ui/core'
import { AppLayout } from '../../common/components/Layout'
import {
  getVideoIdUserCanWatch,
  getVideoIdUserIsAt,
  getCarouselVideos,
} from '../video-helpers'
import { initializeGA } from '../../utils/googleAnalytics'

export const VideosPage = () => {
  const { getIsLoggedIn } = useAuth()
  const history = useHistory()

  const [myProgress, setMyProgress] = useState<VideoProgress[]>([])
  const [isWatchedMap, setIsWatchedMap] = useState<Map<string, boolean>>(
    new Map()
  )
  const [isViewingAllVideos, setIsViewingAllVideos] = useState(false)

  const { loading, data } = useGetRequest<{ videos: Video[] }>(`/videos`, {
    videos: [],
  })

  const isLoggedIn = getIsLoggedIn()

  const videoIdUserIsAt =
    data.videos && getVideoIdUserIsAt(data.videos, myProgress)

  const toggleIsViewingAllVideos = () =>
    setIsViewingAllVideos(!isViewingAllVideos)

  const getVideoIdToVisit = (videoCardId: string) => {
    return getVideoIdUserCanWatch({
      videoId: videoCardId,
      videos: data.videos,
      videoIdUserIsAt,
      isLoggedIn,
      isWatchedMap,
    })
  }

  const onVideoCardClick = (videoCardId: string) => {
    const videoIdUserCanVisit = getVideoIdToVisit(videoCardId)

    if (videoIdUserCanVisit) {
      history.push({
        pathname: `/video/${videoIdUserCanVisit}`,
      })
    }
  }

  useEffect(() => {
    const fetchProgress = () => {
      makeRequest<Progress>({
        url: '/my-progress',
        method: 'GET',
        onSuccess: data => {
          setMyProgress(data.progress)
          setIsWatchedMap(
            data.progress.reduce((m, p) => {
              m.set(p.id, !!p.completedAt)
              return m
            }, new Map())
          )
        },
      })
    }

    isLoggedIn && fetchProgress()
  }, [isLoggedIn])

  useEffect(() => {
    initializeGA('HaklarimiOgrenmekIstiyorum')
  }, [])

  const renderPageContent = () => {
    if (data?.videos.length > 0) {
      const { videos } = data

      return (
        <Fragment>
          <TopBar myProgress={myProgress} />
          <AppLayout>
            <Box mt={5} mb={15}>
              <VideoActions
                toggleIsViewingAllVideos={toggleIsViewingAllVideos}
                videoIdUserIsAt={
                  getVideoIdUserIsAt(videos, myProgress) || videos[0].id
                }
              />
              {isViewingAllVideos ? (
                <VideosList
                  videos={videos}
                  videoIdUserIsAt={getVideoIdUserIsAt(videos, myProgress)}
                  getCanWatch={id => Boolean(getVideoIdToVisit(id))}
                  handleVideoClick={onVideoCardClick}
                  isWatchedMap={isWatchedMap}
                />
              ) : (
                <VideoCarousel
                  videos={getCarouselVideos(
                    videos,
                    isLoggedIn ? videoIdUserIsAt : null
                  )}
                  handleClick={onVideoCardClick}
                  getCanWatch={id => Boolean(getVideoIdToVisit(id))}
                  videoIdUserIsAt={getVideoIdUserIsAt(videos, myProgress)}
                  isWatchedMap={isWatchedMap}
                />
              )}
            </Box>
          </AppLayout>
        </Fragment>
      )
    }

    return <NoData />
  }

  return (
    <Page>
      {loading && <LoadingSpinner />}
      {!loading && renderPageContent()}
    </Page>
  )
}
