import { Box, Grid } from '@material-ui/core'

import { Video } from '../../../types/domain'
import { CardVideo } from '../../../common/components/CardVideo'

interface Props {
  videos: Video[]
  videoIdUserIsAt: string
  handleVideoClick: (id: string) => void
  getCanWatch: (id: string) => boolean
  isWatchedMap: Map<string, boolean>
}

export const VideosList = ({ videos, videoIdUserIsAt, handleVideoClick, getCanWatch, isWatchedMap }: Props) => {
  return (
    <Grid container>
      {videos.map(({ id, title, description, thumbnailUrl, order }, i) => (
        <Grid item xs={6} lg={3} md={4} key={id}>
          <Box style={{ cursor: getCanWatch(id) ? 'pointer' : 'default' }}>
            <CardVideo
              title={title}
              order={order}
              thumbnailUrl={thumbnailUrl}
              description={description}
              videoNumber={i + 1}
              isLastWatchedVideo={videoIdUserIsAt === id}
              isWatched={isWatchedMap.get(id)}
              handleClick={() => handleVideoClick(id)}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}
