import { Box, Typography } from '@material-ui/core'

import useStyles from './styles'
import { useVideoSectionContext } from '../context/VideoSectionContext'
import firstSectionImg from '../../../assets/alistirma.png'
import secondSectionImg from '../../../assets/video.png'
import thirdSectionImg from '../../../assets/anket.png'

interface Props {
  section: number
  sectionTitle: string
}

export const VideoSectionPreview = ({ section, sectionTitle }: Props) => {
  const { videoSection, getCanGoToNextStep, onVideoSectionSet } =
    useVideoSectionContext()

  const isClickable = getCanGoToNextStep() && section === 3

  const classes = useStyles({
    isActiveSection: videoSection === section,
    isClickable,
  })

  const getImgSrc = () => {
    if (section === 1) {
      return firstSectionImg
    }

    if (section === 3) {
      return thirdSectionImg
    }

    return secondSectionImg
  }

  const handleClick = () => {
    if (isClickable) {
      onVideoSectionSet(3)
    }
  }

  return (
    <Box className={classes.container} onClick={handleClick}>
      <Box
        className={classes.previewBox}
        display='flex'
        justifyContent='center'>
        <img src={getImgSrc()} alt='Section' className={classes.sectionImg} />
      </Box>
      <Typography variant='body2' className={classes.sectionTitle}>
        {sectionTitle}
      </Typography>
    </Box>
  )
}
