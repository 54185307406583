import { createStyles, makeStyles } from '@material-ui/core'

interface StyleProps {
  isClickable: boolean
  isActiveSection: boolean
}

export default makeStyles(({ breakpoints, palette, spacing }) =>
  createStyles({
    container: {
      width: '100%',
      cursor: ({ isClickable }: StyleProps) => isClickable && 'pointer',
      [breakpoints.up('lg')]: {
        marginBottom: 20,
      },

      '@media (max-width:1120px) and (min-width:992px)': {
        marginBottom: 15,
      },
    },
    previewBox: {
      maxWidth: '100%',
      width: '100%',
      padding: '5% 0',
      overflow: 'auto',
      boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.14)',
      border: ({ isActiveSection }: StyleProps) =>
        isActiveSection && `1.6px solid ${palette.primary.main}`,

      [breakpoints.up('md')]: {
        padding: '10% 0',
      },

      '@media (max-width:1060px) and (min-width:992px)': {
        padding: '8% 0',
      },
    },
    sectionTitle: {
      marginTop: spacing(1),
      textTransform: 'uppercase',
      fontSize: 12,
      color: ({ isActiveSection }: StyleProps) =>
        isActiveSection && palette.primary.main,

      [breakpoints.up('sm')]: {
        fontSize: 14,
      },
    },
    sectionImg: {
      width: '70px',
      height: '70px',
    },
  })
)
