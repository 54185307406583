import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ spacing, palette, breakpoints }) =>
  createStyles({
    container: {
      height: '100%',

      [breakpoints.up('sm')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    space: {
      marginBottom: spacing(2),
    },
    buttonBox: {
      textAlign: 'right',
      display: 'flex',
      justifyContent: 'center',
      [breakpoints.up('sm')]: {
        justifyContent: 'flex-start',
      },
    },
    divider: {
      height: '1px',

      [breakpoints.up('sm')]: {
        width: '1px',
        height: 180,
      },

      [breakpoints.up('md')]: {
        height: 280,
      },
    },
    leftColumnContainer: {
      padding: spacing(6, 0, 4),
      flexBasis: '38%',

      [breakpoints.up('md')]: {
        paddingBottom: spacing(6),
      },
    },
    rightColumnContainer: {
      flexBasis: '35%',
      padding: spacing(6, 0, 6),
    },
    leftColumnButtonBox: {
      display: 'flex',
      justifyContent: 'center',
      [breakpoints.up('sm')]: {
        justifyContent: 'flex-start',
      },
    },
  })
)
