import {
  Close as CloseIcon,
  MailOutline as EmailIcon,
  ChatBubbleOutline as MessageIcon,
  VideocamOutlined,
  PhoneOutlined,
} from '@material-ui/icons'

export const AGE_RANGES = ['13-18', '19-25', '26-35', '36-55', '56-70', '70-100']
export const DEFINITION_OPTIONS = [
  { label: 'İşitme kaybım var.', value: 'ISITME_KAYBIM_VAR' },
  { label: 'Sağırım.', value: 'SAGIRIM' },
  { label: 'İşitme cihazı kullanıyorum.', value: 'ISITME_CIHAZI_KULLANIYORUM' },
  { label: 'İmplant kullanıyorum.', value: 'IMPLANT_KULLANIYORUM' },
  { label: 'Hiçbiri, platformu merak ediyorum.', value: 'HICBIRI' },
]
export const CONTACT_OPTIONS = [
  { icon: <CloseIcon />, label: 'istemiyorum', value: 'ISTEMIYORUM' },
  { icon: <EmailIcon />, label: 'e-posta', value: 'E-POSTA' },
  { icon: <MessageIcon />, label: 'mesaj (SMS, WhatsApp)', value: 'MESAJ' },
  { icon: <PhoneOutlined />, label: 'sesli arama', value: 'SESLI_ARAMA' },
  {
    icon: <VideocamOutlined />,
    label: 'görüntülü görüşme',
    value: 'GORUNTULU_GORUSME',
  },
]
