import { useState, useEffect } from 'react'
import { Api } from './Api'

export const useGetRequest = <R>(
  url: string,
  defaultData: any = [],
  onSuccess?: (data: R) => void
): { loading: boolean; data: R; error: any } => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<R>(defaultData)
  const [error, setError] = useState(null)

  useEffect(() => {
    // Prevents executing setData after the ajax request has been cancelled
    // more info https://github.com/facebook/react/issues/14326#issuecomment-472043812
    let didCancel = false

    const fetchApi = async () => {
      setLoading(true)
      try {
        const response = await Api.get<R>(url)
        if (!didCancel) {
          setData(response.data)
          onSuccess && onSuccess(response.data)
        }
      } catch (e) {
        setError(e.response)
      }
      setLoading(false)
    }

    fetchApi()

    return () => {
      // Because of await this return should not be reached before the ajax response
      didCancel = true
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return { loading, data, error }
}
