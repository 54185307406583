import { makeStyles } from '@material-ui/core/styles'

import { DEFAULT_LAYOUT_PADDING } from '../../../../consts'

const drawerWidth = 255

export default makeStyles(({ breakpoints, spacing, palette, mixins }) => ({
  headerGridContainer: {
    padding: '15px 0',
  },
  menuWrapper: {
    color: palette.secondary.dark,
    display: 'flex',
    alignItems: 'center',
  },
  menuIcon: {
    fontSize: 30,
  },
  logoWrapper: {
    marginLeft: -DEFAULT_LAYOUT_PADDING, // subtracting the layout padding
    display: 'flex',
    alignItems: 'center',
  },
  itemLabel: {
    color: palette.primary.main,
  },
  logo: {
    cursor: 'pointer',
    objectFit: 'cover',
    width: '38%',
    height: 'auto',

    '@media (max-width:768px) and (min-width:560px)': {
      width: '25%',
    },
  },
  toolbar: mixins.toolbar,
  drawer: {
    backgroundColor: 'white',

    [breakpoints.up('xl')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: spacing(2),
    backgroundImage: ` linear-gradient(to bottom right, #f8ebff, white)`,
  },
  drawerListItems: {
    paddingTop: 2,
    color: palette.secondary.main,
  },
  drawerItemText: {
    paddingLeft: 10,
  },
  divider: {
    opacity: 0.6,
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  content: {
    flex: '1 0 auto',
  },
}))
