import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { Palette } from '@material-ui/core/styles/createPalette'
import { WarningOutlined } from '@material-ui/icons'
import { FC } from 'react'

type AlertLevel = 'error' | 'warning' | 'success'
interface Props {
  level: AlertLevel
}

export const Alert: FC<Props> = ({ level, children }) => {
  const classes = useStyles({ level })

  return (
    <Box className={classes.root}>
      <Box component='span'>{levelToIcon(level)}</Box>
      {children}
    </Box>
  )
}

const levelToColor = (level: AlertLevel, palette: Palette) => {
  switch (level) {
    case 'error':
      return {
        background: palette.error.main,
        color: 'white',
      }
  }
}

const levelToIcon = (level: AlertLevel) => {
  switch (level) {
    case 'error':
      return <WarningOutlined />
  }
}

const useStyles = makeStyles<Theme, { level: AlertLevel }>(
  ({ spacing, transitions, palette }) =>
    createStyles({
      root: {
        marginBottom: spacing(2),
        display: 'flex',
        alignItems: 'center',
        padding: spacing(1, 2),
        borderRadius: spacing(0.5),
        background: ({ level }) => levelToColor(level, palette).background,
        color: ({ level }) => levelToColor(level, palette).color,
        '& > span': {
          margin: spacing(0.5, 1, 0, 0),
        },
      },
    })
)
