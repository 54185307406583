import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ spacing, palette, breakpoints }) =>
  createStyles({
    loggedInInfoSection: {
      background: '#484a69',
      padding: spacing(3, 0),
      [breakpoints.up('lg')]: {
        padding: 0,
      },
    },
    notLoggedInfoSection: {
      background: '#484a69',
      padding: spacing(5, 0),

      [breakpoints.up('lg')]: {
        padding: spacing(7, 0),
      },
    },
    topBarContentContainer: {
      [breakpoints.up('lg')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    title: {
      fontWeight: 600,
      paddingBottom: spacing(1),
    },
    infoText: {
      fontWeight: 600,
      // color: palette.primary.main,
      paddingBottom: spacing(1),
      paddingRight: spacing(1),
      color: 'white',
    },
    progressBoxContainer: {
      justifyContent: 'center',
      marginTop: spacing(1),

      [breakpoints.up('lg')]: {
        justifyContent: 'space-between',
        padding: spacing(2, 10),
        background: palette.white.main,
        borderRadius: 20,
        marginTop: 0,
      },
    },

    progressBoxCard: {
      padding: spacing(1),
      background: palette.white.main,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '160px',
      borderRadius: '15%',
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
      border: '1px solid #d4d7dd',

      '& > img': {
        width: '5em',
        height: '5em',
      },

      '&:first-of-type': {
        marginRight: spacing(2),
      },
    },
  })
)
