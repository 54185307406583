import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { Video } from '../../../types/domain'
import useStyles from './styles'
import { CardVideo } from '../CardVideo'
import { Box } from '@material-ui/core'

interface Props {
  videos: Video[]
  handleClick: (id: string) => void
  isInfinite?: boolean
  videoIdUserIsAt?: string | null
  getCanWatch: (id: string) => boolean
  isWatchedMap: Map<string, boolean>
}

export const VideoCarousel = ({
  videos,
  handleClick,
  videoIdUserIsAt,
  getCanWatch,
  isInfinite = true,
  isWatchedMap,
}: Props) => {
  const classes = useStyles()

  const responsive = {
    xs: {
      breakpoint: { max: 575, min: 0 },
      items: 2,
      partialVisibilityGutter: 30,
    },
    sm: {
      breakpoint: { max: 767, min: 576 },
      items: 2,
      partialVisibilityGutter: 30,
    },
    md: {
      breakpoint: { max: 991, min: 768 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    lg: {
      breakpoint: { max: 1199, min: 992 },
      items: 4,
      partialVisibilityGutter: 40,
    },
    xl: {
      breakpoint: { max: 4000, min: 1200 },
      items: 4,
      partialVisibilityGutter: 40,
    },
  }

  return (
    <Carousel
      autoPlaySpeed={3000}
      containerClass={classes.carousel}
      draggable
      minimumTouchDrag={80}
      removeArrowOnDeviceType={['xs', 'sm', 'md']}
      renderButtonGroupOutside
      renderDotsOutside
      responsive={responsive}
      infinite={isInfinite}
      slidesToSlide={1}
      swipeable>
      {videos.map(({ id, title, description, thumbnailUrl, order }, i) => (
        <Box key={id} style={{ cursor: getCanWatch(id) ? 'pointer' : 'default' }}>
          <CardVideo
            title={title}
            order={order}
            thumbnailUrl={thumbnailUrl}
            description={description}
            videoNumber={i + 1}
            isLastWatchedVideo={videoIdUserIsAt && videoIdUserIsAt === id}
            handleClick={() => handleClick(id)}
            isWatched={isWatchedMap.get(id)}
          />
        </Box>
      ))}
    </Carousel>
  )
}
