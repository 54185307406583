import { Method } from 'axios'
import { Api } from './Api'

interface Props<R> {
  url: string
  method?: Method
  onSuccess?: (data: R) => void
  onError?: (e: any) => void
  payload?: any
  headers?: { [key: string]: string }
}

export const makeRequest = async <R>({
  url,
  method = 'POST',
  payload,
  onError,
  onSuccess,
  headers,
}: Props<R>): Promise<[R | null, any]> => {
  try {
    const { data } = await Api({ url, method, data: payload, headers })
    onSuccess && onSuccess(data)
    return [data, null]
  } catch (e) {
    onError && onError(e.response && e.response.data)
    return [null, e.response]
  }
}
