import { FC } from 'react'
import { Grid, IconButton, Paper, Typography } from '@material-ui/core'
import useStyles from './styles'
import { Align } from '../Align'
import { KeyboardArrowLeft } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

interface Props {
  backBtnText?: string
}

export const LayoutPaper: FC<Props> = ({ backBtnText, children }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Grid container alignItems='center'>
      <Grid item xs={12} lg={6} md={7}>
        <Align vertical fullWidth>
          {backBtnText && (
            <Align
              onClick={() => history.push('/')}
              fullWidth
              justify='flex-start'
              align='center'>
              <IconButton className={classes.iconButton}>
                <KeyboardArrowLeft className={classes.arrowIcon} />
              </IconButton>
              <Typography className={classes.backBtnText}>
                {backBtnText}
              </Typography>
            </Align>
          )}
          <Paper className={classes.contentPaper}>{children}</Paper>
        </Align>
      </Grid>
    </Grid>
  )
}
