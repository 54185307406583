import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ palette, spacing, breakpoints }) =>
  createStyles({
    header: {
      background: palette.white.main,
      boxShadow: 'none',
      padding: spacing(1.5, 0),
    },
    logo: {
      cursor: 'pointer',
      width: '45%',
      height: 'auto',
      marginTop: 4
    },
    title: {
      display: 'block',
      color: palette.primary.main,
    },
    menuItem: {
      cursor: 'pointer',
      color: palette.primary.main,
    },
    itemText: {
      marginRight: spacing(4),
    },
    content: {
      flexGrow: 1,
      height: '93vh',
      background: palette.white.main,
    },
  })
)
