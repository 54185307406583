import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { createBrowserHistory } from 'history'

import { AuthProvider } from './common/contexts/auth'
import Router from './router/Router'
import { GlobalStyles } from './GlobalStyles'
import theme from './theme'
import NotificationProvider from './common/contexts/notification'

const App = () => {
  const history = createBrowserHistory()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      <NotificationProvider>
        <AuthProvider history={history}>
          <Router history={history} />
        </AuthProvider>
      </NotificationProvider>
    </ThemeProvider>
  )
}

export default App
