import { useCallback, useEffect, useState } from 'react'
import { makeRequest } from '../../../common/makeRequest'
import { AdminPage } from '../../../common/components/AdminPage'
import { Table } from '../../../common/components/Table'
import { User } from '../../../types/domain'
import { UserTableRow } from './TableRow'
import { Align } from '../../../common/components/Align'
import { Button } from '@material-ui/core'
import { Api } from '../../../common/Api'
import { format } from 'date-fns'

const tableHeaders = [
  { label: 'ID' },
  { label: 'Email' },
  { label: 'Is Admin' },
]

export const UsersList = () => {
  const [users, setUsers] = useState<User[]>([])

  const handleDownload = async () => {
    try {
      const { data, headers } = await Api.get('/admin/users/export', {
        responseType: 'blob',
        data: true,
      })
      const type = headers['content-type']
      const blob = new Blob([data], { type: type })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `user-export${format(new Date(), 'MM-dd-yyyy')}.json`
      link.click()
    } catch (err) {
      console.log(err)
      // TODO
    }
  }

  const getUsers = useCallback(() => {
    makeRequest<{ users: User[] }>({
      url: '/admin/users',
      method: 'GET',
      onSuccess: ({ users }) => {
        setUsers(users)
      },
      onError: err => {
        console.log(err?.message || 'Something went wrong')
      },
    })
  }, [])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const topBar = (
    <Align fullWidth justify='flex-end' margin={[-0.5, 0, 1, 0]}>
      <Button variant='contained' color='primary' onClick={handleDownload}>
        Export
      </Button>
    </Align>
  )

  return (
    <AdminPage
      topBar={topBar}
      mainContent={
        <Table
          headers={tableHeaders}
          rows={users.map(u => (
            <UserTableRow user={u} />
          ))}
        />
      }
    />
  )
}
