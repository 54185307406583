import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ spacing, palette }) =>
  createStyles({
    iconButton: {
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
      marginRight: spacing(2),
      fontSize: spacing(3),
      background: palette.white.main,
      cursor: 'pointer',

      '&:hover': {
        background: 'rgba(255,255,255, 0.6)',
      },
    },
    arrowIcon: {
      fill: palette.primary.main,
      fontSize: 32,
    },
    backBtnText: {
      color: palette.white.main,
      cursor: 'pointer',
    },
    contentPaper: {
      marginTop: spacing(2),
      padding: spacing(9, 5),
      backgroundColor: 'rgba(255,255,255, 0.8)',
    },
  })
)
