import { FC } from 'react'
import {
  createStyles,
  makeStyles,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

export interface TableHeader {
  label: string
}

interface Props {
  headers: TableHeader[]
  rows: JSX.Element[]
  stickyHeader?: boolean
}

export const Table: FC<Props> = ({ headers, rows, stickyHeader }) => {
  const classes = useStyles()
  return (
    <MuiTable stickyHeader={stickyHeader} className={classes.root}>
      <TableHead>
        <TableRow>
          {headers.map(({ label }) => (
            <TableCell>{label}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </MuiTable>
  )
}

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    root: {
      minHeight: 0,
      // flexGrow: 1,
      // height: '85vh',
      // '& > tbody > tr, & > thead > tr': {
      //   maxHeight: spacing(4),
      // },
    },
  })
)
