import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
p, h1, h2, h3, h4, h5, h6  {
  padding: 0;
  margin: 0;
  font-style: normal;
}

body, html {
  background-color: white !important;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  height: 100%;
}

#root {
  height: 100%;
}

a:link, a:visited {
  color: #4f1d94; 
}

a:-webkit-any-link:active {
    color: -webkit-link;
}

img {
  max-inline-size: 100%;
  block-size: auto;
}

input {
  outline: none;    
    -webkit-appearance: none;
    -moz-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;    
}
`
