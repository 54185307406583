import {
  createContext,
  useContext,
  useState,
  useEffect,
  FC,
  useCallback,
} from 'react'
import { makeRequest } from '../../makeRequest'

interface UserData {
  id: string
  email: string
  communicationPreference: string[]
  phoneNumber: string | null
  ageRange: string
  definitions: string[]
  isAdmin: boolean
}

interface RegisterBody {
  email: string
  password: string
  communicationPreference: string[]
  phoneNumber: string | null
  ageRange: string
  definitions: string[]
}

interface AuthState {
  isLoading: boolean
  getIsLoggedIn: () => boolean
  isAdmin: () => boolean
  loginError?: string
  registerError?: string
  registeredLogin: (email: string, password: string) => void
  register: (b: RegisterBody) => void
  logout: () => void
  userData: UserData
  updatePassword: (payload: {
    oldPassword: string
    newPassword: string
  }) => Promise<[any, any]>
  updatePersonalInfo: (d: UserData) => Promise<[any, any]>
}

// @ts-ignore
export const AuthContext = createContext<AuthState>({})

export const AuthProvider: FC<{ history: any }> = ({ children, history }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [loginError, setLoginError] = useState('')
  const [registerError, setRegisterError] = useState('')
  const [userData, setUserData] = useState<UserData>({} as UserData)

  const registeredLogin = (email: string, password: string) => {
    setIsLoading(true)
    makeRequest<UserData>({
      url: '/login',
      method: 'POST',
      payload: { email, password },
      onSuccess: user => {
        setUserData(user)
        setLoginError('')
        setIsLoading(false)
        history.push('/haklarimi-ogrenmek-istiyorum')
      },
      onError: err => {
        setLoginError(err?.message || 'Bilinmeyen hata')
        setIsLoading(false)

        setTimeout(() => {
          setLoginError('')
        }, 40000)
      },
    })
  }

  const logout = useCallback(() => {
    makeRequest({
      url: '/logout',
      method: 'POST',
      onSuccess: () => {
        setUserData({} as UserData)
        history.push('/kayitli-kullanici-giris')
      },
      onError: err => {
        console.log(err)
      },
    })
  }, [history])

  const getUserData = useCallback(async () => {
    setIsLoading(true)
    return makeRequest<UserData>({
      url: '/me',
      method: 'GET',
      onSuccess: user => {
        setUserData(user)
        setIsLoading(false)
      },
      onError: err => {
        if (err?.status === 401) {
          logout()
        }
        setIsLoading(false)
        console.log(err)
      },
    })
  }, [logout])

  const register = (payload: RegisterBody) => {
    makeRequest({
      url: '/register',
      method: 'POST',
      payload,
      onSuccess: () => {
        history.push('/kayitli-kullanici-giris')
        setRegisterError('')
      },
      onError: err => {
        console.log(err)
        setRegisterError(err?.message || 'Bilinmeyen hata')

        setTimeout(() => {
          setRegisterError('')
        }, 40000)
      },
    })
  }

  const updatePersonalInfo = async ({
    ageRange,
    communicationPreference,
    definitions,
    phoneNumber,
  }: UserData) => {
    return makeRequest<any>({
      url: '/update-personal-information',
      method: 'POST',
      payload: { ageRange, communicationPreference, definitions, phoneNumber },
      onSuccess: updated => setUserData(updated),
      onError: err => {
        console.log(err?.message) // TODO
      },
    })
  }

  const updatePassword = async (payload: {
    oldPassword: string
    newPassword: string
  }) => {
    return makeRequest({
      url: '/update-password',
      method: 'POST',
      payload,
      onSuccess: () => {
        // TODO
      },
      onError: err => {
        console.log(err?.message) // TODO
      },
    })
  }

  const getIsLoggedIn = () => {
    return !!userData.id
  }

  const isAdmin = () => {
    return userData.isAdmin
  }

  useEffect(() => {
    getUserData()
  }, [getUserData])

  useEffect(() => {
    setLoginError('')
    setRegisterError('')
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        loginError,
        registeredLogin,
        logout,
        getIsLoggedIn,
        isAdmin,
        userData,
        register,
        registerError,
        updatePersonalInfo,
        updatePassword,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
