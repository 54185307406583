import { Box, Divider, Typography } from '@material-ui/core'

import './styles.css'

export const KVKKText = () => {
  return (
    <Box className='c8'>
      <Typography className='c5'>
        <span className='c1'>Sevgili Kullan&#305;c&#305;m&#305;z, </span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c9'>
        <span className='c0'>
          <a
            className='c19'
            href='https://www.google.com/url?q=http://www.sagirkadinhak.com&amp;sa=D&amp;source=editors&amp;ust=1647428321650041&amp;usg=AOvVaw3Ko_UK58rzrfb3dhAm_gw4'>
            www.sagirkadinhak.com
          </a>
        </span>
        <span>/</span>
        <span className='c0'>www.sa&#287;&#305;rkad&#305;nhak.com</span>
        <span className='c1'>
          &nbsp;internet sitesinin sahibi olan Dem Derne&#287;i olarak
          ki&#351;isel verilerinin gizlili&#287;i ve g&uuml;venli&#287;i bizim
          i&ccedil;in &ccedil;ok &ouml;nemli. &nbsp;
        </span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c1'>
          Kullan&#305;c&#305;m&#305;z olarak senin ki&#351;isel verilerini
          nas&#305;l i&#351;ledi&#287;imizi ve 6698 say&#305;l&#305;
          Ki&#351;isel Verilerin Korunmas&#305; Kanunu kapsam&#305;nda sahip
          oldu&#287;un haklar&#305; anlatmak istiyoruz. &nbsp;
        </span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c15'>Bu nedenle a&#351;a&#287;&#305;da yer alan </span>
        <span className='c13'>
          Ki&#351;isel Verilerinin &#304;&#351;lenmesine &#304;li&#351;kin
          Ayd&#305;nlatma Metni
        </span>
        <span className='c1'>&rsquo;ni seninle payla&#351;&#305;yoruz. </span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c9'>
        <span className='c1'>
          E&#287;er a&#351;a&#287;&#305;daki metinde a&ccedil;&#305;k
          olmad&#305;&#287;&#305;n&#305;
          d&uuml;&#351;&uuml;nd&uuml;&#287;&uuml;n veya
          a&ccedil;&#305;klamam&#305;z&#305; istedi&#287;in bir konu olursa,
          bizimle yaz&#305;l&#305; olarak ileti&#351;ime ge&ccedil;ebilirsin.
          Sorular&#305;na cevap verebilir, ayd&#305;nlatma metnimizi
          sorular&#305;n &#305;&#351;&#305;&#287;&#305;nda
          geli&#351;tirebiliriz.
        </span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c1'>Sevgilerimizle,</span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c1'>Dem Derne&#287;i </span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Divider />
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      {/* <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography> */}
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c6'>Dem Derne&#287;i </span>
      </Typography>
      <Typography className='c5'>
        <span className='c6'>
          Ki&#351;isel Verilerin &#304;&#351;lenmesine &#304;li&#351;kin
          Ayd&#305;nlatma Metni
        </span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c0'>
          <a
            className='c19'
            href='https://www.google.com/url?q=http://www.sagirkadinhak.com&amp;sa=D&amp;source=editors&amp;ust=1647428321655616&amp;usg=AOvVaw0TBQIOV5u8T4Ozp1VgGm0i'>
            www.sagirkadinhak.com
          </a>
        </span>
        <span>/</span>
        <span className='c0'>www.sa&#287;&#305;rkad&#305;nhak.com</span>
        <span className='c15'>&nbsp;internet s</span>
        <span className='c15 c16'>itesi</span>
        <span className='c15 c16'>&nbsp;</span>
        <span className='c15 c16'>k</span>
        <span className='c15 c16'>u</span>
        <span className='c15'>
          llan&#305;c&#305;s&#305; olarak yaz&#305;l&#305;, s&ouml;zl&uuml; veya
          elektronik olarak bizimle payla&#351;t&#305;&#287;&#305;n&#305;z
          t&uuml;m ki&#351;isel bilgileriniz bizim i&ccedil;in &ccedil;ok
          de&#287;erli. Ki&#351;isel verilerini, zorunlu
          olmad&#305;&#287;&#305;m&#305;z hi&ccedil;bir durumda i&#351;lemiyoruz
          veya saklam&#305;yoruz. Bir sivil toplum kurulu&#351;u olarak, bizimle
          payla&#351;t&#305;&#287;&#305;n ki&#351;isel verilerini yaln&#305;zca
          belirli, a&ccedil;&#305;k ve me&#351;ru bir neden varsa, yaln&#305;zca
          bu durumun gerektirdi&#287;i &ouml;l&ccedil;&uuml;de i&#351;liyor ve
          asgari s&uuml;rede muhafaza ediyoruz.
        </span>
      </Typography>
      <Typography className='c2'>
        <span className='c6'></span>
      </Typography>
      <ol className='c3 lst-kix_99sc5y773xk6-0 start' start={1}>
        <li className='c5 c18 c10 li-bullet-0'>
          <span className='c6'>Tan&#305;mlar</span>
        </li>
      </ol>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c1'>
          Ayd&#305;nlatma metnimizi daha sade tutmak ve anlam birli&#287;ini
          sa&#287;lamak i&ccedil;in a&#351;a&#287;&#305;da
          tan&#305;mlad&#305;&#287;&#305;m&#305;z ifadeler, metin
          i&ccedil;erisinde a&#351;a&#287;&#305;da
          yapt&#305;&#287;&#305;m&#305;z tan&#305;mlardaki anlamlar&#305;
          ta&#351;&#305;maktad&#305;r.
        </span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c13'>Ki&#351;isel Veri:</span>
        <span className='c1'>
          &nbsp;Kimli&#287;i belirli veya belirlenebilir ger&ccedil;ek
          ki&#351;iye ili&#351;kin her t&uuml;rl&uuml; bilgiyi ifade eder.
        </span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c13'>
          Ki&#351;isel Verilerin Korunmas&#305; Kanunu (&ldquo;KVKK&rdquo;):
        </span>
        <span className='c1'>
          &nbsp;7 Nisan 2016 tarihinde Resmi Gazete&rsquo;de yay&#305;nlanarak
          y&uuml;r&uuml;rl&uuml;&#287;e giren 6698 say&#305;l&#305; Ki&#351;isel
          Verilerin Korunmas&#305; Kanunu&rsquo;nu ifade eder.
        </span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c13'>
          Sa&#287;&#305;r Kad&#305;n Hak (&lsquo;&lsquo;SKH&rsquo;&rsquo;):
        </span>
        <span className='c15'>
          &nbsp; Dem Derne&#287;i&rsquo;nin sahip oldu&#287;u{' '}
        </span>
        <span className='c0'>
          <a
            className='c19'
            href='https://www.google.com/url?q=http://www.sagirkadinhak.com&amp;sa=D&amp;source=editors&amp;ust=1647428321658407&amp;usg=AOvVaw1eg-EO-6Nsh0iuNVo-Mo_l'>
            www.sagirkadinhak.com
          </a>
        </span>
        <span>/</span>
        <span className='c0'>www.sa&#287;&#305;rkad&#305;nhak.com</span>
        <span className='c1'>&nbsp;internet sitesini ifade eder.</span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c13'>Veri Sorumlusu:</span>
        <span className='c1'>
          &nbsp;Merkezi Bereketzade Mah. &#350;i&#351;hane Sok. No: 3 / 3
          Beyo&#287;lu - &#304;stanbul adresinde bulunan 34201034
          k&uuml;t&uuml;k numaral&#305; Dem Derne&#287;i&rsquo;ni ifade eder.
        </span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <ol className='c3 lst-kix_99sc5y773xk6-0' start={2}>
        <li className='c5 c18 c10 li-bullet-0'>
          <span className='c13'>
            Ki&#351;isel Verilerin &#304;&#351;lenme Amac&#305;
          </span>
        </li>
      </ol>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c1'>
          Her zaman belirtti&#287;imiz gibi, ki&#351;isel verilerini;
        </span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <ul className='c3 lst-kix_qwfn645kyhn7-0 start'>
        <li className='c5 c12 li-bullet-1'>
          <span className='c1'>
            Hukuka ve d&uuml;r&uuml;stl&uuml;k kurallar&#305;na uygun
            &#351;ekilde,
          </span>
        </li>
        <li className='c5 c12 li-bullet-2'>
          <span className='c1'>
            Do&#287;ru ve gerekti&#287;inde g&uuml;ncel olarak,
          </span>
        </li>
        <li className='c5 c12 li-bullet-1'>
          <span className='c1'>
            Belirli, a&ccedil;&#305;k ve me&#351;ru ama&ccedil;lar i&ccedil;in,
          </span>
        </li>
        <li className='c5 c12 li-bullet-1'>
          <span className='c1'>
            &#304;&#351;lendikleri ama&ccedil;la ba&#287;lant&#305;l&#305;,
            s&#305;n&#305;rl&#305; ve &ouml;l&ccedil;&uuml;l&uuml; olarak,
          </span>
        </li>
      </ul>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c1'>
          i&#351;liyoruz. Ayr&#305;ca saklamam&#305;z gereken bir ki&#351;isel
          veri olursa, bunu yaln&#305;zca ilgili mevzuatta
          &ouml;ng&ouml;r&uuml;len veya amac&#305;m&#305;zla
          s&#305;n&#305;rl&#305; asgari s&uuml;re boyunca muhafaza ediyoruz.
        </span>
      </Typography>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c1'>
          Verilerini i&#351;lememizdeki temel ama&ccedil;, senin platformumuz
          SKH&rsquo;den daha efektif faydalanabilmendir.. Bu nedenle sitemizde
          anonim olarak saklanan &ccedil;erezler kullanabiliriz. Bizimle
          payla&#351;t&#305;&#287;&#305;n &uuml;yelik verilerini, sitemizi
          kullanabilmen i&ccedil;in &uuml;ye oldu&#287;un s&uuml;rece
          saklayabiliriz. Ayr&#305;ca, talep etmen halinde derne&#287;imizin
          faaliyetleri ile ilgili olarak sana ula&#351;mak i&ccedil;in de
          ileti&#351;im bilgilerini saklayabiliriz. Biraz daha a&ccedil;&#305;k
          s&#305;ralamam&#305;z gerekirse:
        </span>
      </Typography>
      <Typography className='c2 c10 c20'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c1'>Dem Derne&#287;i faaliyetleri;</span>
      </Typography>
      <Typography className='c2' id='h.gjdgxs'>
        <span className='c1'></span>
      </Typography>
      <ul className='c3 lst-kix_evjada3nolsj-0 start'>
        <li className='c5 c12 li-bullet-1'>
          <span className='c1'>
            SKH &uuml;zerinden sa&#287;lanacak ve s&uuml;rekli olarak
            geli&#351;tirilecek i&ccedil;erikler ile ilgili her t&uuml;rl&uuml;
            &ccedil;evrimi&ccedil;i ve &ccedil;evrimd&#305;&#351;&#305;
            etkinlikler d&uuml;zenlemek;d&uuml;zenlemek amac&#305;yla
            &ccedil;a&#287;r&#305;da bulunmak,
          </span>
        </li>
        <li className='c5 c12 li-bullet-1'>
          <span className='c1'>
            SKH ile ili&#351;kili olmaks&#305;z&#305;n Dem
            Derne&#287;i&rsquo;nin amac&#305;na uygun olarak gerekti&#287;inde
            i&#351;tirakler ve payda&#351;lar ile &ccedil;evrimi&ccedil;i ve
            &ccedil;evrimd&#305;&#351;&#305; etkinlikler d&uuml;zenlemek;
            d&uuml;zenlemek amac&#305;yla &ccedil;a&#287;r&#305;da bulunmak,
          </span>
        </li>
        <li className='c5 c12 li-bullet-1'>
          <span className='c1'>
            Dem Derne&#287;i&rsquo;nin amac&#305;na uygun fark&#305;ndal&#305;k
            &ccedil;al&#305;&#351;malar&#305; yapmak, kamuoyunu bilgilendirmek
            ve bilgiyi do&#287;rulamak,
          </span>
        </li>
        <li className='c5 c12 li-bullet-1'>
          <span className='c1'>
            Dem Derne&#287;i&rsquo;nin amac&#305;na uygun olarak
            ara&#351;t&#305;rmalar yapmak, derlemek ve anonim olarak kamuoyu ile
            payla&#351;mak, &nbsp;
          </span>
        </li>
        <li className='c5 c12 li-bullet-1'>
          <span className='c1'>
            &#304;zne tabii olarak yard&#305;m toplamak ve yard&#305;m toplamak
            i&ccedil;in &ccedil;a&#287;r&#305;da bulunmak,
          </span>
        </li>
        <li className='c5 c12 li-bullet-2'>
          <span className='c1'>Burs vermek, </span>
        </li>
        <li className='c5 c12 li-bullet-1'>
          <span className='c1'>
            Dem Derne&#287;i&rsquo;nin amac&#305;na uygun olarak
            gerekti&#287;inde i&#351;tirakler ve payda&#351;lar ile birlikte
            sosyal sorumluluk ve sosyal giri&#351;imcilik projeleri
            geli&#351;tirmek, ger&ccedil;ekle&#351;tirmek ve uygulamak,
          </span>
        </li>
      </ul>
      <Typography className='c2'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5'>
        <span className='c1'>
          i&ccedil;in ki&#351;isel verilerini i&#351;leyebiliriz, ama s&ouml;z
          veriyoruz ki, bu ama&ccedil;lar&#305;n gerektirdi&#287;i
          s&#305;n&#305;r&#305; a&#351;mayaca&#287;&#305;z.
        </span>
      </Typography>
      <Typography className='c2 c7'>
        <span className='c6'></span>
      </Typography>
      <ol className='c3 lst-kix_99sc5y773xk6-0' start={3}>
        <li className='c5 c10 c18 li-bullet-0'>
          <span className='c13'>Ki&#351;isel Verilerin Aktar&#305;m&#305;</span>
        </li>
      </ol>
      <Typography className='c2 c7'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5 c7'>
        <span className='c1'>
          KVKK&rsquo;n&#305;n 8. ve 9. maddeleri uyar&#305;nca ki&#351;isel
          verileriniz yukar&#305;da say&#305;lan ama&ccedil;lar dahilinde,
        </span>
      </Typography>
      <Typography className='c5 c7'>
        <span className='c1'>
          Dem Derne&#287;i&rsquo;nin faaliyetlerinin
          s&uuml;rd&uuml;r&uuml;lebilmesi i&ccedil;in ki&#351;isel veri
          i&#351;leme &#351;artlar&#305; ve ama&ccedil;lar&#305;
        </span>
      </Typography>
      <Typography className='c5 c4'>
        <span className='c1'>
          &ccedil;er&ccedil;evesinde gerekli g&ouml;r&uuml;len
          &uuml;&ccedil;&uuml;nc&uuml; ki&#351;ilere (derne&#287;imizin iktisadi
          i&#351;letmesi, i&#351;tirakleri, payda&#351;lar&#305;, proje
          ortaklar&#305;, i&#351;birli&#287;i yap&#305;lan kurumlar,
          tedarik&ccedil;iler, ba&#287;&#305;&#351;&ccedil;&#305;lar, dernek
          y&ouml;neticileri, dernek &ccedil;al&#305;&#351;anlar&#305;, dernek
          genel kurulu, mali m&uuml;&#351;avirler, dan&#305;&#351;manl&#305;k
          hizmeti al&#305;nan kurumlar,hukuken yetkili kamu kurumu ve
          kurulu&#351;lar&#305;, hukuken yetkili &ouml;zel hukuk ki&#351;ileri
          ya da KVKK &#351;artlar&#305;na uygun olmak kayd&#305;yla yurt
          d&#305;&#351;&#305; &uuml;lkeler) aktar&#305;labilecektir.
        </span>
      </Typography>
      <Typography className='c2 c4'>
        <span className='c1'></span>
      </Typography>
      <ol className='c3 lst-kix_99sc5y773xk6-0' start={4}>
        <li className='c5 c18 c10 li-bullet-0'>
          <span className='c13'>
            Ki&#351;isel Verilerin Toplanma Y&ouml;ntemi ve Hukuki Sebebi
          </span>
        </li>
      </ol>
      <Typography className='c2 c7'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5 c7'>
        <span className='c1'>
          Ki&#351;isel verileriniz Dem Derne&#287;i taraf&#305;ndan
          &nbsp;yaz&#305;l&#305;, s&ouml;zl&uuml; veya elektronik kanallarla ve
          farkl&#305;
        </span>
      </Typography>
      <Typography className='c5 c7'>
        <span className='c1'>
          hukuki sebeplere dayanarak Dem Derne&#287;i&rsquo;nin faaliyetlerini
          s&uuml;rd&uuml;r&uuml;lebilmesi i&ccedil;in KVKK
        </span>
      </Typography>
      <Typography className='c5 c7'>
        <span className='c1'>
          taraf&#305;ndan &ouml;ng&ouml;r&uuml;len temel ilkelere uygun olarak,
          KVKK&rsquo;n&#305;n 5. ve 6. maddelerinde belirtilen
        </span>
      </Typography>
      <Typography className='c5 c7'>
        <span className='c1'>
          ki&#351;isel veri i&#351;leme &#351;artlar&#305; ve
          ama&ccedil;lar&#305; kapsam&#305;nda bu Ayd&#305;nlatma Metninde
          belirtilen
        </span>
      </Typography>
      <Typography className='c5 c7'>
        <span className='c1'>
          ama&ccedil;larla da toplanabilmekte, i&#351;lenebilmekte ve
          aktar&#305;labilmektedir.
        </span>
      </Typography>
      <Typography className='c5 c4'>
        <span className='c1'>
          Temel olarak verilerin toplanma y&ouml;ntemi, dernek faaliyetleri ve
          sitemize yapt&#305;&#287;&#305;n &uuml;yelik ba&#351;vurusudur.
        </span>
      </Typography>
      <Typography className='c2 c7'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5 c7'>
        <span className='c13'>Ki&#351;isel Veri Sahibinin Haklar&#305;</span>
      </Typography>
      <Typography className='c2 c7'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5 c7'>
        <span className='c1'>
          KVKK&rsquo;n&#305;n 11. maddesinde de d&uuml;zenlendi&#287;i
          &uuml;zere, &nbsp;bize ba&#351;vurarak her zaman;
        </span>
      </Typography>
      <Typography className='c2 c7'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5 c10 c7'>
        <span className='c1'>
          a) Ki&#351;isel verilerinin i&#351;lenip i&#351;lenmedi&#287;ini
          &ouml;&#287;renme,
        </span>
      </Typography>
      <Typography className='c5 c10 c7'>
        <span className='c1'>
          b) Ki&#351;isel verileri i&#351;lenmi&#351;se buna ili&#351;kin bilgi
          talep etme,
        </span>
      </Typography>
      <Typography className='c5 c10 c7'>
        <span className='c1'>
          c) Ki&#351;isel verilerin i&#351;lenme amac&#305;n&#305; ve
          bunlar&#305;n amac&#305;na uygun kullan&#305;l&#305;p
          kullan&#305;lmad&#305;&#287;&#305;n&#305;
        </span>
      </Typography>
      <Typography className='c5 c10 c7'>
        <span className='c1'>&ouml;&#287;renme,</span>
      </Typography>
      <Typography className='c5 c10 c7'>
        <span className='c1'>
          &ccedil;) Yurt i&ccedil;inde veya yurt d&#305;&#351;&#305;nda
          ki&#351;isel verilerin aktar&#305;ld&#305;&#287;&#305;
          &uuml;&ccedil;&uuml;nc&uuml; ki&#351;ileri bilme,
        </span>
      </Typography>
      <Typography className='c5 c10 c7'>
        <span className='c1'>
          d) Ki&#351;isel verilerin eksik veya yanl&#305;&#351;
          i&#351;lenmi&#351; olmas&#305; h&acirc;linde bunlar&#305;n
          d&uuml;zeltilmesini isteme,
        </span>
      </Typography>
      <Typography className='c5 c10 c7'>
        <span className='c1'>
          e) KVKK 7. maddede &ouml;ng&ouml;r&uuml;len &#351;artlar
          &ccedil;er&ccedil;evesinde ki&#351;isel verilerin silinmesini veya yok
        </span>
      </Typography>
      <Typography className='c5 c7 c10'>
        <span className='c1'>edilmesini isteme,</span>
      </Typography>
      <Typography className='c5 c10 c7'>
        <span className='c1'>
          f) (d) ve (e) bentleri uyar&#305;nca yap&#305;lan i&#351;lemlerin,
          ki&#351;isel verilerin aktar&#305;ld&#305;&#287;&#305;
          &uuml;&ccedil;&uuml;nc&uuml; ki&#351;ilere
        </span>
      </Typography>
      <Typography className='c5 c10 c7'>
        <span className='c1'>bildirilmesini isteme,</span>
      </Typography>
      <Typography className='c5 c10 c7'>
        <span className='c1'>
          g) &#304;&#351;lenen verilerin m&uuml;nhas&#305;ran otomatik sistemler
          vas&#305;tas&#305;yla analiz edilmesi suretiyle
        </span>
      </Typography>
      <Typography className='c5 c10 c7'>
        <span className='c1'>
          ki&#351;inin kendisi aleyhine bir sonucun ortaya
          &ccedil;&#305;kmas&#305;na itiraz etme,
        </span>
      </Typography>
      <Typography className='c5 c10 c7'>
        <span className='c1'>
          &#287;) Ki&#351;isel verilerin kanuna ayk&#305;r&#305; olarak
          i&#351;lenmesi sebebiyle zarara u&#287;ramas&#305; h&acirc;linde
        </span>
      </Typography>
      <Typography className='c5 c10 c7'>
        <span className='c1'>zarar&#305;n giderilmesini talep etme </span>
      </Typography>
      <Typography className='c2 c11'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5 c11'>
        <span className='c1'>haklar&#305;na sahipsin.</span>
      </Typography>
      <Typography className='c2 c7'>
        <span className='c1'></span>
      </Typography>
      <Typography className='c5 c4'>
        <span className='c15'>
          Yukar&#305;da belirtilen haklar&#305;n ile ilgili Dem
          Derne&#287;i&rsquo;ne yaz&#305;l&#305; olarak (&ouml;rne&#287;in
          e-mail ile info@demdernek.org&rsquo;a) &nbsp;ba&#351;vurman halinde,
          talebinin niteli&#287;ine g&ouml;re en ge&ccedil; 30 (otuz) g&uuml;n
          i&ccedil;erisinde, ama elimizden gelen en k&#305;sa s&uuml;rede
          &uuml;cretsiz olarak yan&#305;t verece&#287;iz.
        </span>
      </Typography>
      <Typography className='c17'>
        <span className='c14'></span>
      </Typography>
    </Box>
  )
}
