import { Fragment, useState } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { Box, Typography } from '@material-ui/core'
import * as Yup from 'yup'

import useStyles from './styles'
import { makeRequest } from '../../common/makeRequest'
import { Page } from '../../common/components/Page'
import { AppLayout } from '../../common/components/Layout'
import { Align } from '../../common/components/Align'
import { Space } from '../../common/components/Space'
import { Input } from '../../common/components/Input'
import { Button } from '../../common/components/Button'
import { Alert } from '../../common/components/Alert'
import { useNotification } from '../../common/contexts/notification'
import { LayoutPaper } from '../../common/components/LayoutPaper'

export const ForgotPassword = () => {
  const classes = useStyles()

  const [forgotPassErr, setForgotPassErr] = useState('')
  const { setNotification } = useNotification()

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Geçersiz e-posta adresi.')
      .required('Lütfen kayıtlı olduğun e-posta adresini gir.'),
  })

  const onSubmit = (
    { email }: { email: string },
    helpers: FormikHelpers<any>
  ) => {
    makeRequest({
      url: '/request-password-reset',
      method: 'POST',
      payload: { email },
      onSuccess: () => {
        console.log('Success??')
        setNotification({
          type: 'success',
          title: 'E-posta adresine şifre yenileme linki gönderdik.',
        })
        helpers.resetForm()
      },
      onError: err => {
        setForgotPassErr(err?.message || 'Something went wrong')
      },
    })
  }

  const layoutContent = (
    <Fragment>
      {forgotPassErr && <Alert level='error'>{forgotPassErr}</Alert>}
      <Typography align='center' className={classes.contentTitle}>
        Şifre Yenileme Talebi
      </Typography>
      <Typography className={classes.infoText}>
        Şifreni unuttuysan üzülme, kolayca buradan yenileyebilirsin. Bu internet
        sitesine kayıt olduğun e-posta adresini gir. E-posta kutuna gelen
        bağlantıya tıklayarak şifreni yenile.
      </Typography>
      <Space />
      <Formik
        id='forgot-password-form'
        initialValues={{ email: '' }}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}>
        {({ handleSubmit }) => (
          <Form>
            <Align vertical>
              <Input
                name='email'
                label='E-posta adresi'
                type='email'
                fullWidth
              />
              <Space size={1.5} />
              <Button
                onClick={handleSubmit}
                variant='secondary'
                text='Link gönder'
              />
            </Align>
          </Form>
        )}
      </Formik>
      <Space />
    </Fragment>
  )

  return (
    <Page>
      <Box className={classes.containerBox}>
        <AppLayout styles={{ height: '100%' }}>
          <Align justify='center' height={100}>
            <LayoutPaper backBtnText='Geri dön'>{layoutContent}</LayoutPaper>
          </Align>
        </AppLayout>
      </Box>
    </Page>
  )
}
