import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ palette }) =>
  createStyles({
    arrowContainer: {
      background: palette.white.main,
      borderRadius: 50,
    },
    carouselImg: {
      background: palette.white.main,
      opacity: 0.3,
    },
    carousel: {
      '& > .react-multiple-carousel__arrow': {
        backgroundColor: palette.white.main,
        border: `2px solid ${palette.primary.main}`,

        '& > .hover': {
          backgroundColor: palette.white.main,
        },
      },

      '& > .react-multiple-carousel__arrow::before': {
        color: palette.primary.main,
        fontSize: 24,
      },

      '& > .react-multiple-carousel__arrow--left': {
        position: 'absolute',
        left: 0,
        marginTop: -50,
        paddingRight: 11,

        '@media (max-width:1020px) and (min-width:992px)': {
          marginTop: -60,
        },
      },

      '& > .react-multiple-carousel__arrow--right': {
        position: 'absolute',
        right: 0,
        paddingLeft: 11,
        marginTop: -50,

        '@media (max-width:1020px) and (min-width:992px)': {
          marginTop: -60,
        },
      },
    },
  })
)
