import { TextField } from '@material-ui/core'
import { FC } from 'react'

interface Props {
  label: string
  value: string
  setValue: (v: string) => void
  error?: string
}

export const VideoUrlInput: FC<Props> = ({ label, value, setValue, error }) => {
  return (
    <TextField
      variant='outlined'
      value={value}
      label={label}
      fullWidth
      size='small'
      error={!!error}
      helperText={error}
      onChange={({ target }) => setValue(target.value)}
    />
  )
}
