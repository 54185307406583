import { useState } from 'react'
import {
  Avatar,
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core'
import styled from 'styled-components'
import { Align } from '../../common/components/Align'
import { Page } from '../../common/components/Page'
import { useAuth } from '../../common/contexts/auth'
import useStyles from './styles'
import { ArrowForwardIos } from '@material-ui/icons'
import { Space } from '../../common/components/Space'
import { ProfileSettings } from './ProfileSettings'
import { AppLayout } from '../../common/components/Layout'
import { useGetRequest } from '../../common/useGetRequest'
import { Progress } from '../../types/domain'
import { Certification } from './Certification'
import { LoadingSpinner } from '../../common/components/LoadingSpinner/Loading'

export const Profile = () => {
  const classes = useStyles()
  const { palette } = useTheme()
  const { userData, logout } = useAuth()
  const { data, loading } = useGetRequest<Progress>('/my-progress', [])
  const [selectedTab, setSelectedTab] = useState(0)

  const TABS = () => [
    { label: 'Profil Ayarları', Component: <ProfileSettings /> },
    { label: 'Katılım Belgesi', Component: <Certification progress={data} /> },
  ]

  return (
    <Page>
      <AppLayout>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} md={4}>
              <Paper elevation={5} className={classes.nav}>
                <Align vertical fullWidth>
                  <Align align='center' justify='center' padding={[6, 8]}>
                    <Align vertical align='center'>
                      <Avatar className={classes.userIcon}>
                        <Box component='span'>
                          {userData.email[0].toLocaleUpperCase('TR')}
                        </Box>
                      </Avatar>
                      <Space size={2} />
                      <Typography>{userData.email}</Typography>
                    </Align>
                  </Align>
                  {TABS().map(({ label }, i) => (
                    <ProfileTab
                      selected={i === selectedTab}
                      color={palette.primary.main}
                      key={label}
                      onClick={() => setSelectedTab(i)}>
                      <Align justify='space-between'>
                        <Typography variant='body1'>{label}</Typography>
                        <ArrowForwardIos />
                      </Align>
                    </ProfileTab>
                  ))}
                  <ProfileTab onClick={logout}>
                    <Typography>Çıkış</Typography>
                  </ProfileTab>
                </Align>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box className={classes.tabContainer}>
                {TABS()[selectedTab]?.Component}
              </Box>
            </Grid>
          </Grid>
        )}
      </AppLayout>
    </Page>
  )
}

const ProfileTab = styled.div<{ selected?: boolean; color?: any }>`
  padding: 1em 1em;
  cursor: pointer;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  border-color: ${({ selected, color }) => selected && `${color}`};
  color: ${({ selected, color }) => selected && color};
  :hover {
    opacity: 0.7;
  }
`
