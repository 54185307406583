import { Choice, Question, QuestionType, Video } from '../../../../types/domain'

export const emptyVideo = (): Video => ({
  id: '',
  createdAt: new Date().toISOString(),
  description: '',
  title: '',
  videoUrl: '',
  thumbnailUrl: '',
  questions: [],
  order: '' as any,
  isNew: true,
})

export const emptyQuestion = (type: QuestionType): Question => ({
  id: Date.now().toString(),
  answer: '' as any,
  type,
  order: 1,
  text: '',
  videoUrl: '',
  thumbnailUrl: '',
  choices: Array.from({ length: 4 }).map((c, i) => emptyChoice(Date.now().toString() + i, String.fromCharCode(65 + i))),
  isNew: true,
})

export const emptyChoice = (id = Date.now().toString(), label: any = ''): Choice => ({
  id,
  label,
  text: '',
  videoUrl: '',
  thumbnailUrl: '',
  isNew: true,
})
