export const teamList = [
  {
    name: 'Ayşe Damla',
    surname: 'İşeri Sunman',
    role: 'Koordinatör',
    src: 'https://storage.googleapis.com/bukalemun-bucket/biz/damla_siyahbeyaz.jpg',
  },
  {
    name: 'Beyza',
    surname: 'Ünal',
    role: 'İçerik Danışmanı',
    src: 'https://storage.googleapis.com/bukalemun-bucket/biz/beyza.jpeg',
  },
  {
    name: 'Burcu',
    surname: 'Aydın Pişkin',
    role: 'TİD Danışmanı / Anlatıcı',
    src: 'https://storage.googleapis.com/bukalemun-bucket/biz/burcu.jpeg',
  },
  {
    name: 'Büşra',
    surname: 'Kılıç',
    role: 'Koordinasyon Ekibi Üyesi',
    src: 'https://storage.googleapis.com/bukalemun-bucket/biz/busra.jpeg',
  },
  {
    name: 'Dicle',
    surname: 'Sönmezoğlu',
    role: 'Koordinasyon Ekibi Üyesi',
    src: 'https://storage.googleapis.com/bukalemun-bucket/biz/dicle_siyahbeyaz.jpg',
  },
  {
    name: 'Doğan',
    surname: 'Batman',
    role: 'Çekim Ekibi Lideri',
    src: 'https://storage.googleapis.com/bukalemun-bucket/biz/doğan_siyahbeyaz.jpg',
  },
  {
    name: 'Emirhan',
    surname: 'Ergün',
    role: 'Çekim Ekibi Üyesi',
    src: 'https://storage.googleapis.com/bukalemun-bucket/biz/emirhan.jpeg',
  },
  {
    name: 'Esra',
    surname: 'Karadağ',
    role: 'TİD Tercümanı',
    src: 'https://storage.googleapis.com/bukalemun-bucket/biz/esra.jpeg',
  },
  {
    name: 'Gamze',
    surname: 'Cudal',
    role: 'TİD Danışmanı / Anlatıcı',
    src: 'https://storage.googleapis.com/bukalemun-bucket/biz/gamze_siyahbeyaz.jpeg',
  },
  {
    name: 'Gizem',
    surname: 'Tanay Aksaç',
    role: 'İçerik Danışmanı',
    src: 'https://storage.googleapis.com/bukalemun-bucket/biz/gizem.jpeg',
  },
  {
    name: 'Görkem',
    surname: 'Koçkaya',
    role: 'Full-Stack Developer',
    src: 'https://storage.googleapis.com/bukalemun-bucket/biz/görkem_siyahbeyaz.jpg',
  },
  {
    name: 'Öner',
    surname: 'Adıyaman',
    role: 'Tasarımcı',
    src: 'https://storage.googleapis.com/bukalemun-bucket/biz/öner_siyahbeyaz.jpg',
  },
  {
    name: 'Selin',
    surname: 'Hamamciyan Serdar',
    role: 'Görsel İletişim Sorumlusu',
    src: 'https://storage.googleapis.com/bukalemun-bucket/biz/selin_siyahbeyaz.jpg',
  },
  {
    name: 'Volkan',
    surname: 'Kurt',
    role: 'TİD Tercümanı',
    src: 'https://storage.googleapis.com/bukalemun-bucket/biz/volkan.jpeg',
  },
]
