import styled from 'styled-components'
import { DEFAULT_LAYOUT_PADDING } from '../../consts'

interface Props {
  className?: string
  styles?: any
  pt?: string
  pr?: string
  pb?: string
  pl?: string
}

export const AppLayout: React.FC<Props> = ({ children, ...rest }) => (
  <Container {...rest}>
    <Wrapper>{children}</Wrapper>
  </Container>
)

const Container = styled.div<Props>`
  padding: ${({ pt, pr, pb, pl }) => {
    return `${pt || 0}px ${pr || DEFAULT_LAYOUT_PADDING}px ${pb || 0}px ${
      pl || DEFAULT_LAYOUT_PADDING
    }px`
  }};

  ${({ styles }) => styles && styles};
`

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  height: 100%;
`
