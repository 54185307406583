import { useHistory } from 'react-router-dom'

import useStyles from './styles'
import { Page } from '../../common/components/Page'
import { AppLayout } from '../../common/components/Layout'
import { Button } from '../../common/components/Button'
import { useAuth } from '../../common/contexts/auth'
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { initializeGA } from '../../utils/googleAnalytics'
import { useEffect } from 'react'

export const HomePage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { getIsLoggedIn } = useAuth()

  const isLoggedIn = getIsLoggedIn()

  const onClick = () => {
    if (isLoggedIn) {
      return history.push('/haklarimi-ogrenmek-istiyorum')
    }

    history.push('/giris')
  }

  useEffect(() => {
    initializeGA('Anasayfa')
  }, [])

  return (
    <Page>
      <Box className={classes.homeContainer}>
        <AppLayout>
          <Grid container>
            <Grid item xs={12} lg={6} md={6}>
              <Paper className={classes.paper}>
                <Typography variant='h1' className={classes.title}>
                  Burası <span>SAĞIR KADIN HAK</span>
                </Typography>
                <Typography className={classes.description}>
                  Sağır ve İşitme Engelli Kadın Hakları Eğitim Platformu
                </Typography>
                <Button onClick={onClick} text='Haklarımı öğrenmek istiyorum' />
              </Paper>
            </Grid>
          </Grid>
        </AppLayout>
      </Box>
    </Page>
  )
}
