import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ spacing }) =>
  createStyles({
    titleBox: {
      margin: '20px 0 20px',
    },
    title: {
      paddingBottom: spacing(0.8),
    },
    carouselWrapper: {
      margin: '30px -10px 20px',
    },
  })
)
