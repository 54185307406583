import { TableCell, TableRow } from '@material-ui/core'
import { Check, Close } from '@material-ui/icons'
import { FC } from 'react'
import { User } from '../../../types/domain'

interface Props {
  user: User
}

export const UserTableRow: FC<Props> = ({ user: { id, email, isAdmin } }) => {
  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{isAdmin ? <Check /> : <Close />}</TableCell>
    </TableRow>
  )
}
