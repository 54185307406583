import { Button, Paper } from '@material-ui/core'
import { isEqual } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { makeRequest } from '../../../common/makeRequest'
import { useGetRequest } from '../../../common/useGetRequest'
import { AdminPage } from '../../../common/components/AdminPage'
import { Align } from '../../../common/components/Align'
import { Table } from '../../../common/components/Table'
import { PracticalInfoEntry, PRACTICAL_INFO_CATEGORIES } from '../../../types/domain'
import { validateVimeoURL } from '../../../utils/validateVimeoURL'
import { PracticalInfoEntryDetail } from './PracticalInfoEntryDetail'
import { PracticalInfoTableRow } from './TableRow'

const tableHeaders = [{ label: 'Kategori' }, { label: 'Sira' }, { label: 'Baslik' }]

export const PracticalInfo: FC<{}> = () => {
  const [practicalInfoEntries, setPracticalInfoEntries] = useState([])
  const [selectedEntry, setSelectedEntry] = useState<PracticalInfoEntry | null>(null)
  const [videoUrlValidationResults, setVideoUrlValidationResults] = useState<{
    [key: string]: string
  }>({})

  useGetRequest('/practical-information', {}, d => {
    const pi = Object.keys(d)
      .reduce((arr, category) => [...arr, ...d[category]], [])
      .sort((a, b) => a.order - b.order)
    setPracticalInfoEntries(pi)
  })

  const updatePracticalInfoEntryProperty = (field: keyof PracticalInfoEntry, value: any) => {
    setSelectedEntry(s => ({ ...s, [field]: value }))
  }

  const savePracticalInfoEntry = async () => {
    const {
      result: { error, videoUrl, thumbnailUrl },
    } = await validateVimeoURL('', '', selectedEntry.videoUrl)
    if (error) {
      return setVideoUrlValidationResults({ videoUrl: error })
    }
    const { isNew, ...entry } = selectedEntry
    const payload = { ...entry, thumbnailUrl, videoUrl }
    if (isNew) {
      makeRequest<PracticalInfoEntry>({
        url: '/admin/practical-information',
        method: 'POST',
        payload,
        onSuccess: created => {
          setPracticalInfoEntries(ee => [...ee, created])
          setSelectedEntry(created)
        },
        onError: err => console.log(err), //TODO
      })
    } else {
      makeRequest({
        url: `/admin/practical-information/${selectedEntry.id}`,
        method: 'PUT',
        payload,
        onSuccess: () => {
          setPracticalInfoEntries(ee => ee.map(e => (e.id === selectedEntry.id ? selectedEntry : e)))
        },
        onError: err => console.log(err), //TODO
      })
    }
  }

  const deletePracticalInfoEntry = () => {
    makeRequest({
      url: `/admin/practical-information/${selectedEntry.id}`,
      method: 'DELETE',
      onSuccess: () => {
        setPracticalInfoEntries(ee => ee.filter(e => e.id !== selectedEntry.id))
        setSelectedEntry(null)
      },
      onError: err => console.log(err), //TODO
    })
  }

  const handleNewPracticalInfoEntryClick = () => {
    setSelectedEntry({
      id: Date.now().toString(),
      category: PRACTICAL_INFO_CATEGORIES[0]?.id,
      content: '',
      order: 0,
      title: '',
      thumbnailUrl: '',
      videoUrl: '',
      isNew: true,
    })
  }

  const topBar = (
    <Align fullWidth justify='flex-end' margin={[-0.5, 0, 1, 0]}>
      <Button variant='contained' color='primary' onClick={handleNewPracticalInfoEntryClick}>
        Pratik Bilgi Ekle
      </Button>
    </Align>
  )

  useEffect(() => {
    setVideoUrlValidationResults({})
  }, [selectedEntry?.id])

  return (
    <AdminPage
      topBar={topBar}
      mainContent={
        <Paper style={{ width: '100%' }}>
          <Table
            stickyHeader
            headers={tableHeaders}
            rows={practicalInfoEntries.map(pi => (
              <PracticalInfoTableRow
                key={pi.id}
                infoEntry={pi}
                selectedEntry={selectedEntry}
                setSelectedEntry={setSelectedEntry}
              />
            ))}
          />
        </Paper>
      }
      detailOpen={!!selectedEntry}
      detailComponent={
        <PracticalInfoEntryDetail
          selectedEntry={selectedEntry}
          setSelectedEntry={setSelectedEntry}
          updatePracticalInfoEntryProperty={updatePracticalInfoEntryProperty}
          savePracticalInfoEntry={savePracticalInfoEntry}
          deletePracticalInfoEntry={deletePracticalInfoEntry}
          videoUrlValidationResults={videoUrlValidationResults}
          saveEnabled={
            selectedEntry &&
            !isEqual(
              selectedEntry,
              practicalInfoEntries.find(e => e.id === selectedEntry.id)
            )
          }
        />
      }
    />
  )
}
