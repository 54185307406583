import ReactPlayer from 'react-player/vimeo'

import styled from 'styled-components'
import { Box } from '@material-ui/core'

/** Wrap the Video entity in a <figure /> to comply to the HTML semantic */
const StyledFigureContainer = styled.figure`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  margin: 0;
`
const VideoWrapper = styled.div`
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`
const config = {
  playerOptions: {
    autopause: false,
    autoplay: false,
    byline: false,
    portrait: false,
    title: false,
    // dnt: true,
    playsinline: true,
    muted: false,
    controls: true,
  },
}

interface Props {
  url: string
  onEnded?: () => void
  isMuted?: boolean
  isPlaying?: boolean
  shouldLoop?: boolean
  shouldPlayInline?: boolean
  showControls?: boolean
  volume?: number
  width?: number | string
  height?: number | string
}

export const VideoPlayer = ({
  url,
  onEnded,
  shouldLoop = false,
  shouldPlayInline = false,
  isMuted = true,
  isPlaying = false,
  volume = 0,
  width = '100%',
}: Props) => (
  <Box style={{ paddingBottom: 0 }}>
    <StyledFigureContainer>
      <VideoWrapper>
        <ReactPlayer
          config={config}
          controls={false}
          width={width}
          height='unset' /* Height is calculated by using 'padding-bottom: 56.25%' to keep the aspect ratio */
          light={false}
          loop={shouldLoop}
          muted={isMuted}
          onEnded={onEnded}
          playing={isPlaying}
          playsinline={shouldPlayInline}
          url={url}
          volume={volume}
        />
      </VideoWrapper>
    </StyledFigureContainer>
  </Box>
)
