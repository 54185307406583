import { createContext, FC, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { isEqual, set } from 'lodash'
import { makeRequest } from '../../../../common/makeRequest'
import { useGetRequest } from '../../../../common/useGetRequest'
import { Choice, Course, Question, QuestionType, Video } from '../../../../types/domain'
import { validateVimeoURL } from '../../../../utils/validateVimeoURL'
import { emptyChoice, emptyQuestion, emptyVideo } from './emptyFormValues'
import { useNotification } from '../../../../common/contexts/notification'

interface ICourseContext {
  course: Course
  loading: boolean
  selectedVideo: Video | null
  setSelectedVideo: (v: Video | null) => void
  handleAddVideoClick: () => void
  handleAddQuestionClick: (type: QuestionType) => void
  handleAddChoiceClick: (questionId: string) => void
  saveVideo: () => void
  updateVideoProperty: (field: keyof Video, value: any) => void
  updateQuestionProperty: (id: string, field: keyof Question, value: any) => void
  updateChoiceProperty: (questionId: string, choiceId: string, field: keyof Choice, value: any) => void
  videoUrlValidationResults: { [key: string]: string }
  saveEnabled: boolean
  deleteChoice: (questionId: string, choiceId: string) => void
}

// @ts-ignore
const CourseContext = createContext<ICourseContext>(null)

export const CourseProvider: FC = ({ children }) => {
  const { id: courseId } = useParams<{ id: string }>()
  const [course, setCourse] = useState<Course>({ videos: [] } as any)
  const { loading } = useGetRequest(`/admin/courses/${courseId}`, {}, setCourse)
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null)
  const [videoUrlValidationResults, setVideoUrlValidationResults] = useState<{
    [key: string]: string
  }>({})
  const { setNotification } = useNotification()

  // Validates video urls. If correct replaces videoUrl with playerUrl and sets thumbnailUrl.
  // videoPath and thumbnailPath is to specify the location of the video.
  // Example: questions[1].choices[0].videoUrl is for the video of the second question's first choice.
  // Paths are used in lodash.set also to identify errors in `videoUrlValidationResults`
  const validateVideoUrls = async () => {
    const urls: { videoPath: string; thumbnailPath: string; url: string }[] = [
      {
        videoPath: 'videoUrl',
        thumbnailPath: 'thumbnailUrl',
        url: selectedVideo.videoUrl,
      },
      ...selectedVideo.questions.reduce(
        (arr, q, questionIdx) => [
          ...arr,
          {
            videoPath: `questions[${questionIdx}].videoUrl`,
            thumbnailPath: `questions[${questionIdx}].thumbnailUrl`,
            url: q.videoUrl,
          },
          ...q.choices.map((c, choiceIdx) => ({
            videoPath: `questions[${questionIdx}].choices[${choiceIdx}].videoUrl`,
            thumbnailPath: `questions[${questionIdx}].choices[${choiceIdx}].thumbnailUrl`,
            url: c.videoUrl,
          })),
        ],
        []
      ),
    ]
    const results = await Promise.all(
      urls.map(({ videoPath, thumbnailPath, url }) => validateVimeoURL(videoPath, thumbnailPath, url))
    )

    return results
      .map(({ videoPath, thumbnailPath, result: { error, thumbnailUrl, videoUrl } }) => {
        if (error) {
          setVideoUrlValidationResults(rr => ({ ...rr, [videoPath]: error }))
          return false
        } else {
          const selected = selectedVideo
          set(selected, videoPath, videoUrl)
          set(selected, thumbnailPath, thumbnailUrl)
          setSelectedVideo(selected)
          setVideoUrlValidationResults(rr => ({ ...rr, [videoPath]: '' }))
          return true
        }
      })
      .every(valid => valid)
  }

  const handleAddVideoClick = () => {
    setSelectedVideo(emptyVideo())
  }

  const handleAddQuestionClick = (type: QuestionType) => {
    selectedVideo &&
      setSelectedVideo({
        ...selectedVideo,
        questions: [...selectedVideo.questions, emptyQuestion(type)],
      })
  }

  const handleAddChoiceClick = (questionId: string) => {
    selectedVideo &&
      setSelectedVideo({
        ...selectedVideo,
        questions: selectedVideo.questions.map(q =>
          q.id === questionId
            ? {
                ...q,
                choices: [...q.choices, emptyChoice()],
              }
            : q
        ),
      })
  }

  const updateVideoProperty = (field: keyof Video, value: any) => {
    selectedVideo && setSelectedVideo({ ...selectedVideo, [field]: value })
  }

  const updateQuestionProperty = (id: string, field: keyof Question, value: any) => {
    selectedVideo &&
      setSelectedVideo({
        ...selectedVideo,
        questions: selectedVideo.questions.map(q => (q.id === id ? { ...q, [field]: value } : q)),
      })
  }

  const updateChoiceProperty = (questionId: string, choiceId: string, field: keyof Choice, value: any) => {
    const choices =
      selectedVideo?.questions
        .find(q => q.id === questionId)
        ?.choices.map(c => (c.id === choiceId ? { ...c, [field]: value } : c)) || []

    updateQuestionProperty(questionId, 'choices', choices)
  }

  // Only allow deletion of choices if they are new. Can't delete
  // choice of questions that are saved.
  const deleteChoice = (questionId: string, choiceId: string) => {
    const choices = selectedVideo?.questions.find(q => q.id === questionId)?.choices
    const choice = choices.find(c => c.id === choiceId)
    if (!choice.isNew) {
      return
    }

    updateQuestionProperty(
      questionId,
      'choices',
      choices.filter(c => c.id !== choiceId)
    )
  }

  const saveVideo = async () => {
    const valid = await validateVideoUrls()
    if (!valid) {
      return // TODO
    }
    const { isNew, ...payload } = selectedVideo
    if (isNew) {
      makeRequest<Video>({
        url: `/admin/courses/${courseId}/videos`,
        method: 'POST',
        payload,
        onError: err => {
          setNotification({type: 'danger', title: err?.message || 'Bir hata olustu. F12 ye basip tekrar deneyin. Console sekmesinde hata mesaji gozukecektir.'})
          console.log(err)
        },
        onSuccess: created => {
          setCourse(c => ({
            ...c,
            videos: [...c.videos, created],
          }))
          setSelectedVideo(created)
        },
      })
    } else {
      makeRequest<Video>({
        url: `/admin/courses/${courseId}/videos/${selectedVideo.id}`,
        method: 'PUT',
        payload,
        onError: err => {
          setNotification({type: 'danger', title: err?.message || 'Bir hata olustu. F12 ye basip tekrar deneyin. Console sekmesinde hata mesaji gozukecektir.'})
          console.log(err)
        },
        onSuccess: updated => {
          setCourse(c => ({
            ...c,
            videos: c.videos.map(v => (v.id === selectedVideo.id ? updated : v)),
          }))
          setSelectedVideo(updated)
        },
      })
    }
  }

  useEffect(() => {
    setVideoUrlValidationResults({})
  }, [selectedVideo?.id])

  return (
    <CourseContext.Provider
      value={{
        course,
        loading,
        selectedVideo,
        setSelectedVideo,
        handleAddVideoClick,
        saveVideo,
        updateVideoProperty,
        updateQuestionProperty,
        updateChoiceProperty,
        handleAddChoiceClick,
        handleAddQuestionClick,
        videoUrlValidationResults,
        deleteChoice,
        saveEnabled: selectedVideo
          ? !isEqual(
              selectedVideo,
              course.videos.find(v => v.id === selectedVideo.id)
            )
          : false,
      }}>
      {children}
    </CourseContext.Provider>
  )
}

export const useCourseContext = () => useContext(CourseContext)
