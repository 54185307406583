import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { Box, Divider, Typography, useTheme } from '@material-ui/core'
import { BsFillPersonFill } from 'react-icons/bs'
import * as Yup from 'yup'

import { Page } from '../../common/components/Page'
import { Align } from '../../common/components/Align'
import useStyles from './styles'
import { Input } from '../../common/components/Input'
import { AppLayout } from '../../common/components/Layout'
import { Button } from '../../common/components/Button'
import useScreenWidth from '../../common/useScreenWidth'
import { makeRequest } from '../../common/makeRequest'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Geçersiz e-posta adresi.')
    .required(
      'Lütfen kayıt olmak veya giriş yapmak istediğin e-posta adresini yaz.'
    ),
})

export const Login = () => {
  const classes = useStyles()
  const history = useHistory()
  const { palette } = useTheme()
  const { isSmallScreen } = useScreenWidth()

  const onSubmit = ({ email }: { email: string }) => {
    makeRequest({
      url: '/email-check',
      method: 'POST',
      payload: { email },
      onSuccess: () => {
        history.push({ pathname: '/kayitli-kullanici-giris', state: { email } })
      },
      onError: () => {
        // It means user does not exist
        history.push({ pathname: '/kayit-ol', state: { email } })
      },
    })
  }

  const renderLeftColumn = () => (
    <Box>
      <Typography variant='h2' className={classes.space}>
        Misafir
      </Typography>
      <Typography className={classes.space}>
        Eğitim videolarını izlemek için kayıt olmak zorunda değilsin. Kayıt
        olmadan devam et.
      </Typography>
      <div className={classes.leftColumnButtonBox}>
        <Button
          style={{ marginBottom: 50 }}
          onClick={() => history.push('/haklarimi-ogrenmek-istiyorum')}
          text='Haklarımı öğrenmek istiyorum'
        />
      </div>
    </Box>
  )

  const renderRightColumn = () => (
    <Box>
      <Typography variant='h2' className={classes.space}>
        Giriş yap/Kayıt ol
      </Typography>
      <Typography className={classes.space}>
        Kayıt ol, giriş yap ve sitedeki eğitim videoları dışındaki özellikleri
        de gör.
      </Typography>
      <Box width='100%'>
        <Formik
          id='login-form'
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <Form>
              <Align vertical>
                <Input
                  placeholder='e-mail adresin'
                  name='email'
                  type='email'
                  icon={
                    <BsFillPersonFill size={20} color={palette.primary.main} />
                  }
                />
                <Box className={classes.buttonBox}>
                  <Button onClick={handleSubmit} text='Devam et' />
                </Box>
              </Align>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  )

  return (
    <Page>
      <AppLayout styles={{ height: '100%' }}>
        <Box className={classes.container}>
          <Box className={classes.leftColumnContainer}>
            {renderLeftColumn()}
          </Box>
          <Box>
            <Divider
              className={classes.divider}
              orientation={isSmallScreen ? 'horizontal' : 'vertical'}
              flexItem
            />
          </Box>
          <Box className={classes.rightColumnContainer}>
            {renderRightColumn()}
          </Box>
        </Box>
      </AppLayout>
    </Page>
  )
}
