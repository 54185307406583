import { createStyles, makeStyles } from '@material-ui/core'

import backgroundImage from '../../assets/register-page-carousel.png'

export default makeStyles(({ spacing, palette }) =>
  createStyles({
    dialogContent: {
      padding: '0 !important',
    },
    dialogActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '72pt',
    },
    containerBox: {
      height: '100%',
      background: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${backgroundImage}) no-repeat center`,
      backgroundSize: 'cover',
      padding: spacing(7, 0),
    },
    visibilityIcon: {
      fontSize: 20,
      cursor: 'pointer',
    },
    contentTitle: {
      paddingBottom: spacing(1),
      fontWeight: 'bold',
    },
    checkbox: {
      background: 'transparent',
      marginRight: spacing(1.5),
    },
    root: {
      padding: 0,
    },
    text: {
      color: palette.primary.main,
      cursor: 'pointer',
    },
    checkboxError: {
      color: palette.error.main,
      fontSize: 12,
      marginTop: 3,
    },
  })
)
