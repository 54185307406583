import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ palette, breakpoints, spacing }) =>
  createStyles({
    infoCard: {
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.19)',
      padding: spacing(2),
      margin: spacing(2, 0),
    },
    containerBox: {
      [breakpoints.up('md')]: {
        width: '60%',
        marginRight: 'auto',
        marginLeft: 'auto',
      },
      [breakpoints.up('lg')]: {
        width: '40%',
      },
    },
    closeIcon: {
      cursor: 'pointer',
    },
    title: {
      margin: '.5em 0 0 1em',
    },
    boxWrapper: {
      flexBasis: '48%',
    },
    infoCardTitle: {
      fontWeight: 600,
      fontSize: spacing(2),
      color: '#D6BF4F',
    },
    categoryCard: {
      borderRadius: 0,
      cursor: 'pointer',
      color: palette.grey[500],
    },
    selectedCategoryCard: {
      cursor: 'pointer',
      borderRadius: 0,
      borderColor: palette.primary.main,
      color: palette.primary.main,
      border: '1px solid',
    },
    categoryCardIcon: {
      color: palette.grey[500],
      fontSize: spacing(10),
      marginBottom: spacing(0.5),
    },
    selectedCategoryCardIcon: {
      color: palette.primary.main,
      fontSize: spacing(10),
      marginBottom: spacing(0.5),
    },
    thumbnail: {
      width: 'auto',
      height: '10em',
      cursor: 'pointer',
    },
  })
)
