import {
  alpha,
  Box,
  Checkbox,
  createStyles,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  useTheme,
} from '@material-ui/core'
import { Field } from 'formik'
import { FC } from 'react'
import ReactSelect, { components } from 'react-select'

import { Align } from './Align'

export interface DropdownOption {
  label: string
  value: string
  disabled?: boolean
  icon?: any
}

interface Props {
  id?: string
  name: string
  options: DropdownOption[]
  label: string
  multi?: boolean
  fullWidth?: boolean
  styles?: React.CSSProperties
}

export const Dropdown: FC<Props> = ({ name, id, options, label, multi, fullWidth }) => {
  const classes = useStyles({ fullWidth })
  const { palette } = useTheme()

  if (multi) {
    return (
      <Field name={name}>
        {({ field, form: { setFieldValue }, meta: { touched, error } }) => (
          <FormControl className={classes.formControl}>
            <InputLabel
              shrink
              htmlFor={name}
              className={classes.label}
              id='aria-label'
              classes={{
                formControl: classes.formLabelRoot,
              }}>
              {label}
            </InputLabel>
            <ReactSelect
              id={name}
              aria-labelledby='aria-label'
              placeholder='Lütfen cevap/larını seç.'
              isMulti
              name={name}
              options={options}
              hideSelectedOptions={false}
              isClearable={false}
              closeMenuOnSelect={false}
              components={{
                Option: ReactSelectOption,
              }}
              styles={{
                option: s => ({
                  ...s,
                  backgroundColor: '#fff',
                  color: 'black',
                  border: '1px solid #e1e1e1',
                  cursor: 'pointer',
                }),
                menuList: s => ({ ...s, paddingTop: 0, paddingBottom: 0 }),
                input: s => ({ ...s, height: 31 }),
                control: (s: any, { isFocused }: any) => {
                  return {
                    ...s,
                    boxShadow: isFocused ? 'rgb(79 29 148 / 25%) 0 0 0 0.2rem' : 'none',
                    border: isFocused && `${palette.primary.main} !important`,
                  }
                },
              }}
              {...field}
              value={field.value}
              onChange={v => setFieldValue(field.name, v)}
            />
            {touched && error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        )}
      </Field>
    )
  }

  return (
    <Field name={name}>
      {({ field, meta: { touched, error } }) => (
        <FormControl className={classes.formControl}>
          <InputLabel
            htmlFor='input'
            id={name}
            shrink
            aria-label={label}
            className={classes.label}
            classes={{
              formControl: classes.formLabelRoot,
            }}>
            {label}
          </InputLabel>
          <Select
            classes={{ select: classes.input }}
            id={id}
            labelId={name}
            displayEmpty
            error={error && touched}
            {...field}>
            {[{ label: 'Lütfen cevabını seç.', value: '', disabled: true }, ...options].map(o => (
              <MenuItem disabled={o.disabled} value={o.value} key={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
          {touched && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  )
}

const useStyles = makeStyles(({ spacing, transitions, palette }) =>
  createStyles({
    multiSelectInput: {
      '&:focus': {
        background: 'red',
      },
    },
    formLabelRoot: {
      position: 'relative',
      '&+ .MuiInput-formControl': {
        margin: 0,
      },
    },
    formControl: {
      marginBottom: spacing(3),
      width: ({ fullWidth }: { fullWidth: boolean }) => fullWidth && '100%',
    },
    fullWidth: {
      width: '100%',
    },
    root: {
      borderRadius: 4,
    },

    label: {
      fontWeight: 600,
      marginBottom: spacing(1),
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: palette.common.white,
      border: '1px solid #ced4da',
      padding: '10px 12px',
      transition: transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        boxShadow: `${alpha(palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: palette.primary.main,
      },
    },
  })
)

const ReactSelectOption: FC<any> = ({ ...rest }) => (
  <components.Option {...rest}>
    <Align align='center'>
      <Checkbox checked={rest.isSelected} color='primary' />
      <Align align='center'>
        {rest.data.icon}
        <Box ml={6}>{rest.data.label}</Box>
      </Align>
    </Align>
  </components.Option>
)
