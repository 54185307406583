import { createStyles, makeStyles } from '@material-ui/core'

interface Props {
  isLastWatchedVideo: boolean
  isWatched: boolean
}

export default makeStyles(({ spacing, breakpoints, palette }) =>
  createStyles({
    title: {
      marginTop: spacing(2),
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: 16,
      color: ({ isLastWatchedVideo }: Props) =>
        isLastWatchedVideo && palette.primary.main,
    },
    description: {
      color: ({ isLastWatchedVideo }: Props) =>
        isLastWatchedVideo && palette.primary.main,
    },
    card: {
      textAlign: 'center',
      position: 'relative',
      paddingBottom: 5,
    },
    partTitle: {
      position: 'absolute',
      fontWeight: 'bold',
      marginLeft: 'auto',
      marginRight: 'auto',
      textTransform: 'uppercase',
      top: '35%',
      left: 0,
      right: 0,
      zIndex: '5 !important' as any,
      color: ({ isLastWatchedVideo }: Props) =>
        isLastWatchedVideo ? palette.white.main : palette.primary.main,

      [breakpoints.up('sm')]: {
        top: '45%',
      },
    },
    boxWrapper: {
      padding: 10,
    },
    carouselImg: {
      opacity: ({ isWatched }: Props) => (!isWatched ? 0.3 : 1),
      width: '100%',
    },
    lastWatchedBackgroundLayout: {
      opacity: 0.6,
      width: '100%',
      height: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      background: ({ isLastWatchedVideo }: Props) =>
        isLastWatchedVideo && palette.primary.main,
    },
    textWrapper: {
      display: 'inline-block',
    },
  })
)
