import { TableCell, TableRow } from '@material-ui/core'
import { Video } from '../../../../types/domain'

interface Props {
  setSelectedVideo: (v: Video) => void
  selectedVideo: Video | null
  video: Video
}

export const VideoTableRow: React.FC<Props> = ({
  video,
  selectedVideo,
  setSelectedVideo,
}) => {
  const { id, title, order } = video
  return (
    <TableRow
      onClick={() => setSelectedVideo(video)}
      selected={selectedVideo?.id === id}
      hover
      style={{ cursor: 'pointer' }}>
      <TableCell>{order}</TableCell>
      <TableCell>{title}</TableCell>
    </TableRow>
  )
}
