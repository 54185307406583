import { createStyles, makeStyles } from '@material-ui/core'

import backgroundImage from '../../assets/home-carousel.png'

export default makeStyles(({ spacing, palette, breakpoints }) =>
  createStyles({
    homeContainer: {
      background: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${backgroundImage}) no-repeat center`,
      backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',

      [breakpoints.up('md')]: {
        padding: spacing(20, 0),
      },
    },
    title: {
      marginBottom: spacing(1),
      '& > span': {
        color: palette.primary.main,
      },
    },
    description: {
      marginBottom: spacing(4),
      [breakpoints.up('sm')]: {
        padding: spacing(0, 4, 0, 0),
      },
    },
    paper: {
      padding: spacing(8, 5),
      backgroundColor: 'rgba(250, 248, 242, 0.7)',
      margin: spacing(3, 0),

      [breakpoints.up('md')]: {
        margin: spacing(0, 0),
      },
    },
  })
)
