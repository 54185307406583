import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core'
import React, { createContext, useContext, useState } from 'react'
import { Button } from '../../components/Button'

export interface Notification {
  title?: string
  content?: string
  confirmBtnLabel?: string
  type: 'success' | 'danger' | 'warning'
}

interface INotificationContext {
  setNotification: React.Dispatch<React.SetStateAction<Notification>>
}

const NotificationContext = createContext<INotificationContext | null>(null)

const NotificationProvider: React.FC = ({ children }) => {
  const [notification, setNotification] = useState<Notification | null>(null)

  return (
    <NotificationContext.Provider value={{ setNotification }}>
      {children}

      {notification && (
        <Dialog
          open={!!notification}
          onClose={() => setNotification(null)}
          fullWidth
          maxWidth='xs'>
          <Typography style={{ padding: '16px 24px' }}>
            {notification.title}
          </Typography>
          <DialogContent>{notification.content}</DialogContent>
          <DialogActions>
            <Button
              onClick={() => setNotification(null)}
              text={notification.confirmBtnLabel || 'Tamam'}
            />
          </DialogActions>
        </Dialog>
      )}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => useContext(NotificationContext)

export default NotificationProvider
