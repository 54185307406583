import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:1111/api',
})

axiosInstance.interceptors.request.use(
  config => ({ ...config, withCredentials: true }),
  error => Promise.reject(error)
)

export const Api = axiosInstance
