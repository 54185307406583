import { createStyles, makeStyles } from '@material-ui/core'

import backgroundImage from '../../assets/login-page-carousel.png'

export default makeStyles(({ spacing, palette, breakpoints }) =>
  createStyles({
    containerBox: {
      height: '100%',
      background: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${backgroundImage}) no-repeat center`,
      backgroundSize: 'cover',
      padding: spacing(7, 0),
    },
    contentTitle: {
      paddingBottom: spacing(1),
      fontWeight: 'bold',
    },
    loginActions: {
      paddingBottom: spacing(2),
      display: 'flex',
      flexDirection: 'column',

      [breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    checkboxWrapper: {
      order: 2,

      [breakpoints.up('md')]: {
        order: 1,
      },
    },
    visibilityIcon: {
      fontSize: 20,
      cursor: 'pointer',
    },
    linkWrapper: {
      order: 1,
      paddingBottom: spacing(1),

      [breakpoints.up('md')]: {
        order: 2,
        paddingBottom: 0,
      },
    },
  })
)
