import { Box, Grid, Typography } from '@material-ui/core'
import { Align } from '../../common/components/Align'
import { AppLayout } from '../../common/components/Layout'

import useStyles from './styles'
import { Page } from '../../common/components/Page'
import { VideoPlayer } from '../Video/VideoPlayer/VideoPlayer'
import { Space } from '../../common/components/Space'
import { initializeGA } from '../../utils/googleAnalytics'
import { useEffect } from 'react'

export const HowItWorks = () => {
  const classes = useStyles()

  useEffect(() => {
    initializeGA('SitedeNeVar')
  }, [])

  return (
    <Page>
      <Box className={classes.infoSection}>
        <AppLayout>
          <Grid container>
            <Align vertical align='center' fullWidth>
              <Typography variant='h2' className={classes.title} style={{ color: 'white' }}>
                Videolarda ne var?
              </Typography>
              <Typography className={classes.infoText} style={{ color: 'white' }}>
                Platformdaki videoların içerikleri ile ilgili bilgi sahibi olmak için videoyu izleyebilirsin.
              </Typography>
            </Align>
            <Grid item xs={12} style={{ marginBottom: 40 }}>
              <VideoPlayer url='https://player.vimeo.com/video/707278012' />
            </Grid>
            <Align vertical align='center' fullWidth margin={[1, 0, 0]}>
              <Typography variant='h2' className={classes.title}>
                Videolarda kim var?
              </Typography>
              <Typography className={classes.infoText}>
                Platformdaki videolarda karakterler var. Karakterleri tanımak için videoyu izleyebilirsin.
              </Typography>
            </Align>
            <Grid item xs={12}>
              <VideoPlayer url='https://player.vimeo.com/video/707278827' />
            </Grid>
            <Space size={4} />
          </Grid>
        </AppLayout>
      </Box>
    </Page>
  )
}
