import { FC, Fragment, useMemo } from 'react'
import { Formik } from 'formik'
import { createStyles, Grid, makeStyles } from '@material-ui/core'

import { Button } from '../../../common/components/Button'
import { Align } from '../../../common/components/Align'
import { Dropdown, DropdownOption } from '../../../common/components/Dropdown'
import { Input } from '../../../common/components/Input'
import { useAuth } from '../../../common/contexts/auth'
import {
  AGE_RANGES,
  CONTACT_OPTIONS,
  DEFINITION_OPTIONS,
} from '../../../staticContent'

interface Props {
  onSubmit: (values: {
    ageRange: string
    communicationPreference: DropdownOption[]
    definitions: DropdownOption[]
    phoneNumber: string
  }) => Promise<void>
}

const useStyles = makeStyles(({ spacing, breakpoints }) =>
  createStyles({
    leftCol: {
      [breakpoints.up('lg')]: {
        paddingRight: spacing(2),
      },
    },
  })
)

export const PersonalInfoForm: FC<Props> = ({ onSubmit }) => {
  const classes = useStyles()

  const {
    userData: {
      ageRange,
      communicationPreference,
      definitions,
      phoneNumber,
      id,
    },
  } = useAuth()

  const initialValues = useMemo(
    () => ({
      ageRange,
      communicationPreference: (communicationPreference || []).map(p =>
        CONTACT_OPTIONS.find(o => o.value === p)
      ),
      definitions: (definitions || []).map(d =>
        DEFINITION_OPTIONS.find(o => o.value === d)
      ),
      phoneNumber,
    }),
    // eslint-disable-next-line
    [id]
  )

  const phoneNumberNeeded = (commPrefs: DropdownOption[]) =>
    commPrefs.some(p =>
      ['MESAJ', 'SESLI_ARAMA', 'GORUNTULU_GORUSME'].includes(p.value)
    )

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, handleSubmit }) => (
        <Fragment>
          <Grid container>
            <Grid item xs={12} lg={6} className={classes.leftCol}>
              <Dropdown
                name='ageRange'
                options={AGE_RANGES.map(r => ({ label: r, value: r }))}
                label='Yaş aralığı'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <Dropdown
                name='definitions'
                options={DEFINITION_OPTIONS}
                label='Seni en iyi tanımlayan seçenekler hangileri?(Birden fazla se ebilirsin.)'
                multi
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6} className={classes.leftCol}>
              <Dropdown
                name='communicationPreference'
                options={CONTACT_OPTIONS}
                label='İletişim tercihi'
                multi
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              {phoneNumberNeeded(values.communicationPreference) && (
                <Input name='phoneNumber' label='Telefon numarası' fullWidth />
              )}
            </Grid>
          </Grid>
          <Align justify='flex-end'>
            <Button
              onClick={handleSubmit}
              style={{ marginTop: 15 }}
              text='Bilgilerimi güncelle'
            />
          </Align>
        </Fragment>
      )}
    </Formik>
  )
}
