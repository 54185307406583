import { Avatar, Box, Grid, Typography } from '@material-ui/core'
import { Align } from '../../common/components/Align'
import { AppLayout } from '../../common/components/Layout'

import useStyles from './styles'
import { Page } from '../../common/components/Page'
import { LayoutPaper } from '../../common/components/LayoutPaper'
import { initializeGA } from '../../utils/googleAnalytics'
import { teamList } from './teamList'

export const AboutPage = () => {
  const classes = useStyles()
  initializeGA('Biz')

  const renderPeopleInfo = () => (
    <Grid container style={{ marginTop: 30 }}>
      {teamList.map(({ name, surname, role, src }, i) => (
        <Grid item key={i} xs={12} sm={6} md={3} className={classes.personContainer}>
          <Align vertical align='center' className={classes.person}>
            <Avatar alt={`${name} ${surname}`} src={src} className={classes.avatar} />
            <Box component='span'>{name}</Box>
            <Box component='span'>{surname}</Box>
            {role.split('\n').map((str, idx) => (
              <Box component='span' className={classes.role} key={idx}>
                {str}
              </Box>
            ))}
          </Align>
        </Grid>
      ))}
    </Grid>
  )

  return (
    <Page>
      <Box className={classes.containerBox}>
        <AppLayout>
          <Align justify='center'>
            <LayoutPaper>
              <Typography className={classes.infoText}>
                Dem Derneği, Engelli Kadın Derneği ve Türkiye Sağırlar Milli Federasyonu olarak bir araya geldik. “Sağır
                ve İşitme Engelli Kadın Hakları Eğitim Platformu” projesini 2021 - 2022 Sabancı Vakfı Hibe Programı
                desteği ile başlattık. Amacımız; sağır ve işitme engelli kadınların, kadına yönelik şiddet ve şiddetle
                mücadele mekanizmaları başta olmak üzere hakları ile ilgili farkındalığının artması.
              </Typography>
            </LayoutPaper>
          </Align>
        </AppLayout>
      </Box>

      <Box className={classes.teamSection}>
        <Align justify='center' className={classes.sectionTitleBar}>
          <Box className={classes.introTitle} component='span'>
            ekibimiz
          </Box>
        </Align>
        <AppLayout>{renderPeopleInfo()}</AppLayout>
      </Box>
    </Page>
  )
}
