import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ spacing, palette, breakpoints }) =>
  createStyles({
    infoSection: {
      background: `linear-gradient(#484a69 40%, transparent 40%) no-repeat`,
      padding: spacing(5, 0, 6),

      [breakpoints.up('sm')]: {
        paddingBottom: spacing(8),
      },
    },
    title: {
      textAlign: 'center',
    },
    infoText: {
      padding: spacing(3, 0, 3.5),

      [breakpoints.up('md')]: {
        padding: spacing(3, 5, 3.5),
      },
    },
  })
)
