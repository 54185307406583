import { FC } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { Course } from '../pages/admin/Course'
import { PracticalInfo } from '../pages/admin/PracticalInfo'
import { UsersList } from '../pages/admin/Users'
import { ForgotPassword } from '../pages/ForgotPassword'
import { VideosPage } from '../pages/Videos'
import { AboutPage } from '../pages/About'
import { VideoPage } from '../pages/Video'

import { Information } from '../pages/Information'
import { RegisteredLogin } from '../pages/RegisteredLogin'
import { Login } from '../pages/Login'
import { Register } from '../pages/Register'
import { Profile } from '../pages/Profile'
import { ResetPassword } from '../pages/ResetPassword'

import { PrivateRoute } from './PrivateRoute'
import { HowItWorks } from '../pages/HowItWorks'
import { HomePage } from '../pages/Home'

const AppRouter: FC<{ history: any }> = ({ history }) => (
  <Router history={history}>
    <Switch>
      <Route path='/kayitli-kullanici-giris' component={RegisteredLogin} exact />
      <Route path='/kayit-ol' component={Register} exact />
      <Route path='/sifremi-unuttum' component={ForgotPassword} exact />
      <Route path='/giris' component={Login} exact />
      <Route
        path='/sifre-yenile/:token'
        component={({ match }: any) => <Redirect to={{ state: { ...match.params }, pathname: '/sifre-yenile' }} />}
        exact
      />
      <Route path='/sifre-yenile' component={ResetPassword} exact />
      <Route path='/hakkimizda' component={AboutPage} exact />
      <Route path='/pratik-bilgiler/:category' component={Information} exact />
      <Route path='/pratik-bilgiler' component={Information} exact />
      <Route path='/video/:id' component={VideoPage} exact />
      <Route path='/sitede-ne-var' component={HowItWorks} exact />
      <PrivateRoute path='/profil' component={Profile} exact />
      <Route path='/haklarimi-ogrenmek-istiyorum' component={VideosPage} exact />
      <Route path='/' component={HomePage} exact />
      {/* Admin Pages */}
      <PrivateRoute admin path='/admin/users-list' component={UsersList} exact />
      <PrivateRoute admin path='/admin/content/practical-info' component={PracticalInfo} exact />
      <PrivateRoute admin path='/admin/content/courses/:id' component={Course} exact />
    </Switch>
  </Router>
)

export default AppRouter
