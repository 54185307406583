import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ breakpoints, spacing, palette }) => ({
  infoSection: {
    backgroundColor: palette.footer.main,
    padding: spacing(3, 0),

    [breakpoints.up('lg')]: {
      padding: spacing(6, 0),
    },
  },
  footerItemsContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& > div': {
      padding: spacing(1.5, 0),
      color: palette.gray.main,
    },

    [breakpoints.up('lg')]: {
      padding: 0,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& > div': {
        padding: 0,
      },
    },
  },
  itemText: {
    paddingLeft: spacing(1.2),
    color: palette.gray.main,
  },
  footerLogosContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
    },
  },
  logosWrapper: {
    marginBottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: spacing(1.3),
    flexBasis: '80%',

    [breakpoints.up('lg')]: {
      marginTop: 0,
    },

    '@media (max-width:633px) and (min-width:430px)': {
      flexBasis: '65%',
    },

    '@media (max-width:992px) and (min-width:634px)': {
      flexBasis: '40%',
    },
  },
  logoImg: {
    width: '90%',
    maxWidth: '100%',
    height: 'auto',
    padding: spacing(0, 0.5),
  },
  address: {
    [breakpoints.up('lg')]: {
      marginTop: spacing(2.5),
    },
  },
  socialMediaIcon: {
    color: palette.primary.dark,
    cursor: 'pointer',
  },
  bottomBar: {
    backgroundColor: palette.footer.dark,
    color: palette.white.main,
    padding: spacing(1),
  },
  bottomBarText: {
    textAlign: 'center',
    fontSize: 12,
    color: palette.footer.main,

    [breakpoints.up('sm')]: {
      fontSize: 14,
    },
  },
  bottomBarLogo: {
    padding: spacing(0.2, 1),
    maxWidth: '85%',
    height: 'auto',

    [breakpoints.up('lg')]: {
      maxWidth: '50%',
    },
  },
  legalText: {
    fontSize: 12,
    color: palette.gray.main,

    [breakpoints.up('sm')]: {
      fontSize: 14,
    },
  },
}))
