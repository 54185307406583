import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ palette, spacing, breakpoints }) =>
  createStyles({
    questionsContainer: {
      background: '#f6f6f6',
      padding: spacing(3),
    },
    questionBox: {
      marginTop: spacing(1),
      [breakpoints.up('md')]: {
        margin: spacing(0, 0, 0, 2),
      },
    },
    choiceGridContainer: {
      marginTop: spacing(3),

      [breakpoints.up('md')]: {
        marginTop: spacing(5),
      },
    },
    choiceItem: {
      margin: spacing(3, 0),
    },
    firstCol: {
      [breakpoints.up('md')]: {
        paddingRight: spacing(3),
      },
    },
    lastCol: {
      [breakpoints.up('md')]: {
        paddingLeft: spacing(3),
      },
    },
  })
)
