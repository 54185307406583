import { SvgIconProps } from '@material-ui/core'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined'
import { Person, VideoLibrary } from '@material-ui/icons'
import { MdContentCopy } from 'react-icons/md'
import { IoArrowBackCircleSharp } from 'react-icons/io5'

export interface MenuItem {
  label: string
  item: string
  subMenuItems?: SubMenuItem[]
  Icon: (props: SvgIconProps) => JSX.Element
  onClick?: () => void
}

export interface SubMenuItem {
  subLabel: string
  Icon: (props: SvgIconProps) => JSX.Element
  onClick: () => void
}

export const ADMIN_MENU_ITEMS = (
  history: any,
  logout: () => void,
  courses: any[]
): MenuItem[] => [
  {
    label: 'Kullanicilar',
    item: 'users',
    onClick: () => history.push('/admin/users-list'),
    Icon: Person,
  },
  {
    label: 'Site icerigi',
    item: 'users',
    Icon: AssessmentOutlinedIcon,
    subMenuItems: [
      {
        subLabel: 'Pratik Bilgiler',
        Icon: MdContentCopy,
        onClick: () => history.push('/admin/content/practical-info'),
      },
    ],
  },
  {
    label: 'Kurslar',
    item: 'courses',
    Icon: AssessmentOutlinedIcon,
    subMenuItems: courses.map(({ title, id }) => ({
      subLabel: title,
      onClick: () => history.push(`/admin/content/courses/${id}`),
      Icon: VideoLibrary,
    })),
  },
  {
    label: 'Siteye Don',
    item: 'back',
    onClick: () => history.push('/'),
    Icon: IoArrowBackCircleSharp,
  },
  {
    label: 'Çıkış',
    item: 'signout',
    onClick: () => logout(),
    Icon: PowerSettingsNew,
  },
]
