import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ palette, breakpoints, spacing }) =>
  createStyles({
    accordion: {
      boxShadow: '0 3px 9px 0 rgba(21,27,38,.2)',
      border: `1px solid ${palette.gray.light}`,
    },
    accordionRoot: {
      margin: '16px 0',
    },
    leftCol: {
      [breakpoints.up('sm')]: {
        paddingRight: spacing(2),
      },
    },
  })
)
