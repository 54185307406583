import axios from 'axios'

export const validateVimeoURL = async (
  videoPath: string,
  thumbnailPath: string,
  url: string
): Promise<{
  videoPath: string
  thumbnailPath: string
  result: { videoUrl?: string; thumbnailUrl?: string; error?: string }
}> => {
  const r =
    // eslint-disable-next-line
    /(?:http|https)?:?\/?\/?(?:www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/

  const match = url.match(r)
  if (!match) {
    return { videoPath, thumbnailPath, result: { error: 'Hatali Url' } }
  }

  const vimeoId = match[1]

  try {
    const { data } = await axios.get(
      `https://vimeo.com/api/v2/video/${vimeoId}.json`
    )

    return {
      videoPath,
      thumbnailPath,
      result: {
        thumbnailUrl: data[0]?.thumbnail_large,
        videoUrl: `https://player.vimeo.com/video/${vimeoId}`,
      },
    }
  } catch (err) {
    console.log(err)
    return { videoPath, thumbnailPath, result: { error: 'Hatali Url' } }
  }
}
