import { Box, Typography } from '@material-ui/core'
import { CardMedia, Card } from '@material-ui/core'

import useStyles from './styles'

interface Props {
  title: string
  description: string
  order: number
  thumbnailUrl: string
  videoNumber: number
  isLastWatchedVideo?: boolean
  isWatched?: boolean
  handleClick: () => void
}

export const CardVideo = ({
  thumbnailUrl,
  title,
  description,
  isWatched,
  isLastWatchedVideo,
  handleClick,
}: Props) => {
  const classes = useStyles({ isLastWatchedVideo, isWatched })

  return (
    <Box className={classes.boxWrapper}>
      <Card className={classes.card} onClick={handleClick}>
        <CardMedia
          component='img'
          alt='Video Thumbnail'
          image={thumbnailUrl}
          className={classes.carouselImg}
        />
        <Box className={classes.lastWatchedBackgroundLayout} />
      </Card>
      <Box className={classes.textWrapper} onClick={handleClick}>
        <Typography className={classes.title} variant='h6'>
          {title}
        </Typography>
        <Typography className={classes.description}>{description}</Typography>
      </Box>
    </Box>
  )
}
