import { Box, Checkbox, Typography, useTheme } from '@material-ui/core'
import { Fragment, useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { useAuth } from '../../common/contexts/auth'
import { Page } from '../../common/components/Page'
import { Align } from '../../common/components/Align'
import useStyles from './styles'
import { Input } from '../../common/components/Input'
import { Space } from '../../common/components/Space'
import { Button } from '../../common/components/Button'
import { Link, useLocation } from 'react-router-dom'
import { Alert } from '../../common/components/Alert'
import { AppLayout } from '../../common/components/Layout'
import { LayoutPaper } from '../../common/components/LayoutPaper'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'

export const RegisteredLogin = () => {
  const classes = useStyles()
  const { palette } = useTheme()

  const { state } = useLocation<{ email?: string }>()
  const [rememberMe, setRememberMe] = useState(false)
  const { registeredLogin, loginError } = useAuth()

  const [showPassword, setShowPassword] = useState(false)

  const onSubmit = ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    registeredLogin(email, password)
  }

  const SigninSchema = Yup.object().shape({
    email: Yup.string()
      .email('Geçersiz e-posta adresi.')
      .required('Lütfen kayıtlı olduğun e-posta adresini gir.'),
    password: Yup.string().required('Lütfen şifreni gir.'),
  })

  const layoutContent = (
    <Fragment>
      {loginError && <Alert level='error'>{loginError}</Alert>}
      <Typography align='center' className={classes.contentTitle}>
        Giriş yap
      </Typography>
      <Space />
      <Formik
        id='login-form'
        initialValues={{ email: state?.email || '', password: '' }}
        onSubmit={onSubmit}
        validationSchema={SigninSchema}>
        {({ handleSubmit }) => (
          <Form>
            <Align vertical>
              <Input
                name='email'
                label='E-posta adresi'
                type='email'
                fullWidth
              />
              <Space />
              <Input
                name='password'
                label='Şifre'
                type={showPassword ? 'text' : 'password'}
                icon={
                  <Box
                    className={classes.visibilityIcon}
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={e => e.preventDefault()}>
                    {showPassword ? (
                      <MdVisibility color={palette.primary.main} />
                    ) : (
                      <MdVisibilityOff color={palette.primary.main} />
                    )}
                  </Box>
                }
                iconPosition='end'
                fullWidth
              />
              <Space />
              <Box className={classes.loginActions}>
                <Align align='center' className={classes.checkboxWrapper}>
                  <Checkbox
                    style={{ paddingLeft: 0 }}
                    checked={rememberMe}
                    onChange={({ target }) => setRememberMe(target.checked)}
                  />
                  <Typography>Beni hatırla.</Typography>
                </Align>
                <Box className={classes.linkWrapper}>
                  <Link to='/sifremi-unuttum'>
                    <span>Şifremi unuttum.</span>
                  </Link>
                </Box>
              </Box>
              <Button
                onClick={handleSubmit}
                variant='secondary'
                text='Giriş yap'
              />
            </Align>
          </Form>
        )}
      </Formik>
      <Space />
      <Align justify='center' align='center' padding={[1, 0, 0, 0]}>
        <Typography>
          Bir hesabın yok mu? <Link to='/kayit-ol'>Kayıt ol</Link>
        </Typography>
      </Align>
    </Fragment>
  )

  return (
    <Page>
      <Box className={classes.containerBox}>
        <AppLayout styles={{ height: '100%' }}>
          <Align justify='center' height={100}>
            <LayoutPaper backBtnText='Geri dön'>{layoutContent}</LayoutPaper>
          </Align>
        </AppLayout>
      </Box>
    </Page>
  )
}
