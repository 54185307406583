import { Box } from '@material-ui/core'
import { Align } from '../../../common/components/Align'
import { BiPlay } from 'react-icons/bi'

import { useAuth } from '../../../common/contexts/auth'
import useStyles from './styles'
import { useHistory } from 'react-router-dom'

interface Props {
  toggleIsViewingAllVideos: () => void
  videoIdUserIsAt: string | undefined
}

export const VideoActions = ({
  videoIdUserIsAt,
  toggleIsViewingAllVideos,
}: Props) => {
  const classes = useStyles()
  const history = useHistory()

  const { getIsLoggedIn } = useAuth()

  const actionsContainerStyle = {
    padding: '0 10px 10px 0',
  }

  const onContinueWatchingClick = () => {
    videoIdUserIsAt && history.push({ pathname: `/video/${videoIdUserIsAt}` })
  }

  const renderLoggedInVideoActions = () => (
    <Align style={actionsContainerStyle} justify='space-between' align='center'>
      <Box
        className={classes.continueText}
        onClick={onContinueWatchingClick}
        display='flex'
        alignItems='center'>
        <BiPlay className={classes.playIcon} />
        <Box component='span'>Devam Et</Box>
      </Box>
      <Box className={classes.viewAllText} onClick={toggleIsViewingAllVideos}>
        Tüm bölümleri gör
      </Box>
    </Align>
  )
  const renderNotLoggedInVideoActions = () => (
    <Align justify='flex-end' style={actionsContainerStyle}>
      <Box onClick={toggleIsViewingAllVideos} className={classes.viewAllText}>
        Tüm bölümleri gör
      </Box>
    </Align>
  )

  return (
    <Box>
      {getIsLoggedIn()
        ? renderLoggedInVideoActions()
        : renderNotLoggedInVideoActions()}
    </Box>
  )
}
