import { FC, Fragment, useState } from 'react'
import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'

import useStyles from './styles'
import { useHistory } from 'react-router'
import { useAuth } from '../../../../contexts/auth'
import MenuIcon from '@material-ui/icons/Menu'
import { MENU_ITEMS } from '../menuItemList'
import useScreenWidth from '../../../../useScreenWidth'
import { AppLayout } from '../../../Layout'
import logo from '../../../../../assets/logo.png'

export const MobileHeader: FC<{ isLoading?: boolean }> = ({
  children,
  isLoading = false,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { getIsLoggedIn, isAdmin, logout } = useAuth()
  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const { isSmallScreen } = useScreenWidth()

  const handleMobileOpenToggle = () => setIsMobileOpen(!isMobileOpen)

  const menuItems = MENU_ITEMS(getIsLoggedIn(), isAdmin(), history, logout)

  const drawer = (
    <List className={classes.drawerListItems}>
      <Box className={classes.toolbar} />
      <Divider className={classes.divider} />
      {menuItems.map(({ label, onClick }, i) => {
        return (
          <Box key={label}>
            <ListItem
              onClick={onClick}
              key={label}
              className={classes.itemLabel}
              button>
              <ListItemText
                inset
                className={classes.drawerItemText}
                primary={label}
              />
            </ListItem>
            {i === menuItems.length - 1 && (
              <Divider className={classes.divider} />
            )}
          </Box>
        )
      })}
    </List>
  )

  return (
    <Box>
      {isSmallScreen && (
        <Fragment>
          <AppLayout>
            <Grid container className={classes.headerGridContainer}>
              <Grid
                item
                xs={6}
                className={classes.menuWrapper}
                aria-label='open drawer'>
                <MenuIcon
                  className={classes.menuIcon}
                  onClick={handleMobileOpenToggle}
                />
              </Grid>
              <Grid item xs={6} className={classes.logoWrapper}>
                <img
                  alt='Site Logo'
                  className={classes.logo}
                  src={logo}
                  onClick={() => history.push('/')}
                />
              </Grid>
            </Grid>
          </AppLayout>
          <nav className={classes.drawer}>
            <Hidden mdUp implementation='css'>
              <Drawer
                variant='temporary'
                open={isMobileOpen}
                onClose={handleMobileOpenToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                classes={{
                  paper: classes.drawerPaper,
                }}>
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        </Fragment>
      )}
      <main className={classes.content}>
        {isLoading ? <CircularProgress /> : children}
      </main>
    </Box>
  )
}
