import React, { useState, createContext, useContext, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

import {
  QuestionType,
  Video as IVideo,
  VideoProgress,
} from '../../../types/domain'

interface Props {
  videoProgress: VideoProgress[]
  video: IVideo
}
interface IVideoContext {
  videoSection: number
  onVideoSectionSet: (section: number) => void
  getCanGoToNextStep: () => boolean
}

export const VideoSectionContext = createContext<IVideoContext>({
  videoSection: 1,
  onVideoSectionSet: null,
  getCanGoToNextStep: null,
})

/** Video Section Context Provider is responsible for the logic
 * to decide which section the user is at or can get to. Therefore, it determines which
 * component do display. It guards the video sections
 * and makes sure that user cannot skip without completing the previous steps.
 */
export const VideoSectionContextProvider: React.FC<Props> = ({
  videoProgress,
  video,
  children,
}) => {
  const query = useLocation()
  const sectionParam = new URLSearchParams(query.search).get('section')
  const history = useHistory()
  const { id: videoId } = useParams<{ id: string }>()

  const getAreQnsAnswered = (type: QuestionType) => {
    const matchedProgressVideo = videoProgress.find(v => v.id === videoId)

    const videoQnsLength = video?.questions?.filter(
      q => q.type === type
    )?.length
    const progressQnsLength = matchedProgressVideo?.completedQuestions?.filter(
      q => q.type === type
    )?.length

    return (
      videoQnsLength &&
      progressQnsLength &&
      videoQnsLength === progressQnsLength
    )
  }

  const getIsVideoWatched = () => {
    const matchedProgressVideo = videoProgress.find(v => v.id === videoId)
    return !!matchedProgressVideo?.completedAt
  }

  /** Section 1 = initial questions, 2 = video, 3 = last questions */
  const getVideoSectionNum = () => {
    if (!videoProgress?.length) return 1
    const areInitialQnsAnswered = getAreQnsAnswered('pre')
    const areLastQnsAnswered = getAreQnsAnswered('post')
    const isVideoWatched = getIsVideoWatched()

    if (areInitialQnsAnswered) {
      // Guarding against manually typed params
      if (sectionParam === '2') {
        return 2
      }

      if (sectionParam === '3' && areLastQnsAnswered) {
        return 3
      }
      // ---

      if (areLastQnsAnswered) {
        return 2
      }

      return isVideoWatched ? 3 : 2
    }

    return 1
  }

  const [videoSection, setVideoSection] = useState(getVideoSectionNum())

  const onVideoSectionSet = (section: number) => {
    setVideoSection(section)
    history.push({ search: `?section=${section}` })
  }

  const getCanGoToNextStep = () => {
    return getAreQnsAnswered('pre') && getIsVideoWatched() && videoSection === 2
  }

  useEffect(() => {
    onVideoSectionSet(getVideoSectionNum())
    // eslint-disable-next-line
  }, [video.id])

  return (
    <VideoSectionContext.Provider
      value={{
        videoSection,
        onVideoSectionSet,
        getCanGoToNextStep,
      }}>
      {children}
    </VideoSectionContext.Provider>
  )
}

export const useVideoSectionContext = () => useContext(VideoSectionContext)
