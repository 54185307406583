import { Fragment, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Box, Typography } from '@material-ui/core'
import { Form, Formik } from 'formik'

import useStyles from './styles'
import { makeRequest } from '../../common/makeRequest'
import { Page } from '../../common/components/Page'
import { AppLayout } from '../../common/components/Layout'
import { Align } from '../../common/components/Align'
import { Space } from '../../common/components/Space'
import { Button } from '../../common/components/Button'
import { Alert } from '../../common/components/Alert'
import { Input } from '../../common/components/Input'
import { useNotification } from '../../common/contexts/notification'
import { LayoutPaper } from '../../common/components/LayoutPaper'

export const ResetPassword = () => {
  const history = useHistory()
  const classes = useStyles()
  const { setNotification } = useNotification()

  const { state } = useLocation<{ token: string }>()
  const [resetPassErr, setResetPassErr] = useState('')

  const onSubmit = ({
    newPassword,
    confirmedNewPassword,
  }: {
    newPassword: string
    confirmedNewPassword: string
  }) => {
    if (newPassword.trim() !== confirmedNewPassword.trim()) {
      return setNotification({
        type: 'danger',
        title: 'yeni şifre ve şifre tekrarı ayni olmalı',
      })
    }
    makeRequest({
      url: '/reset-password',
      method: 'POST',
      payload: { token: state.token, password: newPassword },
      onSuccess: () => {
        history.push('/kayitli-kullanici-giris')
      },
      onError: err => {
        setResetPassErr(err.message || 'Üzgünüz, bir hata oluştu')
      },
    })
  }

  const layoutContent = (
    <Fragment>
      {resetPassErr && <Alert level='error'>{resetPassErr}</Alert>}
      <Typography align='center' className={classes.contentTitle}>
        şifre yenileme
      </Typography>
      <Space />
      <Formik
        id='login-form'
        initialValues={{ newPassword: '', confirmedNewPassword: '' }}
        onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Form>
            <Align vertical>
              <Input
                name='newPassword'
                label='yeni şifre'
                type='password'
                fullWidth
              />
              <Space />
              <Input
                name='confirmedNewPassword'
                label='yeni şifre (tekrar)'
                type='password'
                fullWidth
              />
              <Space />
              <Button onClick={handleSubmit} text='Submit' />
            </Align>
          </Form>
        )}
      </Formik>
      <Space />
    </Fragment>
  )

  return (
    <Page>
      <Box className={classes.containerBox}>
        <AppLayout>
          <Align justify='center' height={100}>
            <LayoutPaper backBtnText='Geri dön'>{layoutContent}</LayoutPaper>
          </Align>
        </AppLayout>
      </Box>
    </Page>
  )
}
