import { createStyles, Grid, makeStyles } from '@material-ui/core'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { Button } from '../../../common/components/Button'
import { Align } from '../../../common/components/Align'
import { Input } from '../../../common/components/Input'

interface Props {
  onSubmit: (v: { oldPassword: string; newPassword: string; newPasswordRepeated: string }) => Promise<boolean>
}

const useStyles = makeStyles(({ spacing, breakpoints }) =>
  createStyles({
    leftCol: {
      [breakpoints.up('lg')]: {
        paddingRight: spacing(2),
      },
    },
  })
)

export const PasswordResetForm: React.FC<Props> = ({ onSubmit }) => {
  const classes = useStyles()

  const onClickSubmit = async (
    values: {
      oldPassword: string
      newPassword: string
      newPasswordRepeated: string
    },
    formikHelpers: FormikHelpers<any>
  ) => {
    const success = await onSubmit(values)
    if (success) {
      formikHelpers.resetForm()
    }
  }

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: '',
        newPasswordRepeated: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onClickSubmit}>
      {({ handleSubmit }) => (
        <Form>
          <Grid container>
            <Grid item xs={12} lg={6} className={classes.leftCol}>
              <Input name='oldPassword' label='Eski şifre' type='password' autoComplete={false} fullWidth />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Input name='newPassword' label='Yeni şifre' type='password' autoComplete={false} fullWidth />
            </Grid>
            <Grid item xs={12} lg={6} className={classes.leftCol}>
              <Input
                name='newPasswordRepeated'
                label='Yeni şifre (tekrar)'
                type='password'
                autoComplete={false}
                fullWidth
              />
            </Grid>
          </Grid>
          <Align justify='flex-end'>
            <Button onClick={handleSubmit} text='şifre güncelle' />
          </Align>
        </Form>
      )}
    </Formik>
  )
}

const pwErr = 'Şifre en az 6 en fazla 12 karakterden oluşmalı.'
const requiredErr = 'Zorunlu alan.'
const pwMatchErr = 'Yeni ve eski şifre farklı olmalı.'

const validationSchema = Yup.object({
  oldPassword: Yup.string().min(6, pwErr).max(12, pwErr).required(requiredErr),
  newPassword: Yup.string()
    .min(6, pwErr)
    .max(12, pwErr)
    .oneOf([Yup.ref('newPasswordRepeated'), null], pwMatchErr)
    .required(requiredErr),
  newPasswordRepeated: Yup.string()
    .min(6, pwErr)
    .max(12, pwErr)
    .oneOf([Yup.ref('newPassword'), null], pwMatchErr)
    .required(requiredErr),
})
