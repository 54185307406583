import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

interface Props {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  content: string
  title?: string
  confirmBtnText?: string
}

export const ConfirmationDialog: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  content,
  title,
  confirmBtnText,
}) => {
  const confirm = () => {
    onConfirm()
    onClose()
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose}>
          İptal
        </Button>
        <Button variant='contained' onClick={confirm}>
          {confirmBtnText || 'Onayla'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
