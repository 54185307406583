import { AppBar, Grid, Toolbar, Typography } from '@material-ui/core'
import { useHistory } from 'react-router'
import classNames from 'classnames'

import { useAuth } from '../../../../contexts/auth'
import { Button } from '../../../Button'

import { Align } from '../../../Align'
import useStyles from './styles'
import { MENU_ITEMS } from '../menuItemList'
import { AppLayout } from '../../../Layout'
import logo from '../../../../../assets/logo.png'

export const DesktopHeader = () => {
  const classes = useStyles()
  const history = useHistory()
  const { getIsLoggedIn, isAdmin, logout } = useAuth()

  const menuItems = MENU_ITEMS(getIsLoggedIn(), isAdmin(), history, logout)

  const renderMenu = (
    <Align align='center'>
      {menuItems.map(({ label, identity, onClick }, i) => {
        if (identity === 'giris') {
          return <Button onClick={onClick} key={identity} text={label} />
        }

        return (
          <Typography
            color='secondary'
            key={identity}
            className={classNames(classes.menuItem, {
              [classes.itemText]: i !== menuItems.length - 1,
            })}
            onClick={onClick}>
            {label}
          </Typography>
        )
      })}
    </Align>
  )

  return (
    <AppLayout>
      <AppBar position='static' className={classes.header}>
        <Toolbar>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item md={2}>
              <img
                src={logo}
                className={classes.logo}
                alt='Site Logo'
                onClick={() => history.push('/')}
              />
            </Grid>
            <Grid item md={10}>
              <Align justify='flex-end'>{renderMenu}</Align>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </AppLayout>
  )
}
