import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ palette, spacing, breakpoints }) =>
  createStyles({
    sectionContainer: {
      background: "#faf8f2",
      padding: spacing(6, 4),
      marginTop: '-48px'
    },
    firstCol: {
      [breakpoints.up('md')]: {
        paddingRight: spacing(3),
      },
    },
    lastCol: {
      [breakpoints.up('md')]: {
        paddingLeft: spacing(3),
      },
    },
    completedImg: {
      width: '90%',
      [breakpoints.up('md')]: {
        width: '35%',
      },
    },
  })
)
