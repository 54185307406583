import { Button as MuiButton } from '@material-ui/core'

import useStyles from './styles'
interface Props {
  onClick?: () => void
  type?: any
  text?: string
  style?: any
  isDisabled?: boolean
  variant?: 'primary' | 'secondary' | 'tertiary'
}

export const Button: React.FC<Props> = ({
  children,
  text,
  onClick,
  type,
  isDisabled,
  variant = 'primary',
  style,
}) => {
  const classes = useStyles({ variant })

  return (
    <MuiButton
      disabled={isDisabled}
      style={style}
      type={type}
      onClick={onClick}
      className={classes.btn}>
      {text}
      {children && children}
    </MuiButton>
  )
}
