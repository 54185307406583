import { Video, VideoProgress } from '../types/domain'

/** The utility function to have the logic of deciding which video the
 * logged in user is at depending on the sections they've completed.
 */
export const getVideoIdUserIsAt = (videos: Video[], videoProgress: VideoProgress[]): string | undefined => {
  const lastWatchedVideo = videoProgress?.[videoProgress.length - 1]

  const [matchedVideo, matchedVideoIdx] = findVideo(videos, lastWatchedVideo?.id)

  if (!lastWatchedVideo || matchedVideoIdx === -1) {
    return
  }

  if (!matchedVideo.questions?.length) {
    return videos[matchedVideoIdx + 1]?.id
  }

  const areAllSectionsInVideoCompleted =
    lastWatchedVideo.completedAt &&
    lastWatchedVideo.completedQuestions?.length === videos[matchedVideoIdx].questions?.length

  // If all sections are completed, idx + 1 is the next video to watch
  // If the video is not completed entirely, it's still where user is at
  return areAllSectionsInVideoCompleted ? videos[matchedVideoIdx + 1]?.id : videos[matchedVideoIdx]?.id
}

const findVideo = (videos: Video[], id: string): [Video | null, number] => {
  for (let i = 0; i < videos.length; i++) {
    if (videos[i].id === id) {
      return [videos[i], i]
    }
  }
  return [null, -1]
}

/** The utility function to have the logic of deciding which videos the
 *  user can or cannot watch depending on they're logged in or not.
 */
export const getVideoIdUserCanWatch = ({
  videoId,
  videos,
  videoIdUserIsAt,
  isLoggedIn,
  isWatchedMap,
}: {
  videoId: string
  videos: Video[]
  isLoggedIn: boolean
  videoIdUserIsAt?: string
  isWatchedMap: Map<string, boolean>
}): string | undefined => {
  if (!isLoggedIn) {
    return videoId
  }
  if (isWatchedMap.get(videoId)) {
    return videoId
  }

  const currentVideoIdx = videos.findIndex(v => v.id === videoIdUserIsAt)

  if (!videoIdUserIsAt || currentVideoIdx === -1) {
    return videoId === videos[0]?.id ? videoId : undefined
  }

  const videosUserWatchedOrIsWatching = videos.slice(0, currentVideoIdx + 1)

  const isVideoUserCanClick = videosUserWatchedOrIsWatching.find(v => v.id === videoId)

  return isVideoUserCanClick ? videoId : undefined
}

/** The utility function to have the logic of showing the
 * logged in user an indication of the next video to watch
 */
export const getCarouselVideos = (videos: Video[], videoIdUserIsAt?: string, isLoggedIn: boolean = true) => {
  if (videoIdUserIsAt) {
    const videoIndexUserIsAt = videos.findIndex(v => v.id === videoIdUserIsAt)
    let startIdx = videoIndexUserIsAt - 1

    if (!isLoggedIn || videoIndexUserIsAt === 0) {
      startIdx = videoIndexUserIsAt
    }

    return [...videos.slice(startIdx), ...videos.slice(0, startIdx)]
  }

  return videos
}
