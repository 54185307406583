import { Fragment } from 'react'
import { Box, Typography } from '@material-ui/core'

import { Align } from '../../../common/components/Align'
import { AppLayout } from '../../../common/components/Layout'
import { useAuth } from '../../../common/contexts/auth'

import progressTestImg from '../../../assets/video.png'
import progressVideoImg from '../../../assets/anket.png'
import useStyles from './styles'
import { VideoProgress } from '../../../types/domain'

interface Props {
  myProgress: VideoProgress[]
}

export const TopBar = ({ myProgress }: Props) => {
  const classes = useStyles()
  const { getIsLoggedIn } = useAuth()

  const completedQuestionsCount = myProgress?.reduce(
    (total, p) =>
      total +
      (p.completedQuestions || []).filter(q => q.type === 'post').length,
    0
  )

  const renderLoggedInTopBar = () => (
    <Fragment>
      <Box className={classes.loggedInInfoSection}>
        <AppLayout>
          <Box className={classes.topBarContentContainer}>
            <Align align='center' justify='center'>
              <Typography className={classes.infoText} variant='h3'>
                Merhaba. Burada eğitim videoları, anketler ve alıştırmalar var.
                Bugüne kadar;
              </Typography>
            </Align>

            <Align align='center' className={classes.progressBoxContainer}>
              <Box className={classes.progressBoxCard}>
                <img src={progressTestImg} alt='Izlenen video thumbnail' />
                <Typography
                  variant='body2'
                  color='primary'
                  style={{ fontWeight: 600 }}>
                  {myProgress?.length || 0} video
                </Typography>
                <Typography variant='caption' color='textSecondary'>
                  izledin
                </Typography>
              </Box>
              <Box className={classes.progressBoxCard}>
                <img src={progressVideoImg} alt='Cozulen video thumbnail' />
                <Typography
                  variant='body2'
                  color='primary'
                  style={{ fontWeight: 600 }}>
                  {completedQuestionsCount || 0} alıştırma
                </Typography>
                <Typography variant='caption' color='textSecondary'>
                  yaptın
                </Typography>
              </Box>
            </Align>
          </Box>
        </AppLayout>
      </Box>
    </Fragment>
  )

  const renderNotLoggedInTopBar = () => (
    <Box className={classes.notLoggedInfoSection}>
      <AppLayout>
        <Align vertical>
          <Typography className={classes.infoText}>
            Merhaba. Burada siteye kayıt olmadan eğitim videoları
            izleyebilirsin.
          </Typography>
        </Align>
      </AppLayout>
    </Box>
  )

  return (
    <Fragment>
      {getIsLoggedIn() ? renderLoggedInTopBar() : renderNotLoggedInTopBar()}
    </Fragment>
  )
}
