import { FC } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../common/contexts/auth'

interface Props {
  component: any
  location?: any
  path?: string
  exact?: boolean
  redirectPath?: string
  admin?: boolean
}

export const PrivateRoute: FC<Props> = ({
  component: Component,
  redirectPath,
  admin,
  ...rest
}) => {
  const { isLoading, getIsLoggedIn, isAdmin } = useAuth()
  const allowed = admin ? isAdmin() : getIsLoggedIn()

  if (isLoading) {
    return null
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (!allowed) {
          return (
            <Redirect
              to={{
                pathname: '/kayitli-kullanici-giris',
                state: { from: props.location },
              }}
            />
          )
        }
        if (redirectPath) {
          return <Redirect to={redirectPath} />
        }
        return <Component {...props} />
      }}
    />
  )
}
