import { Fragment, useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
  Button as MuiButton,
} from '@material-ui/core'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'

import { useAuth } from '../../common/contexts/auth'
import { Page } from '../../common/components/Page'
import { Input } from '../../common/components/Input'
import { Dropdown, DropdownOption } from '../../common/components/Dropdown'
import { Space } from '../../common/components/Space'
import { Button } from '../../common/components/Button'
import { Alert } from '../../common/components/Alert'
import {
  AGE_RANGES,
  CONTACT_OPTIONS,
  DEFINITION_OPTIONS,
} from '../../staticContent'
import { AppLayout } from '../../common/components/Layout'
import { Align } from '../../common/components/Align'
import useStyles from './styles'
import { LayoutPaper } from '../../common/components/LayoutPaper'
import { useLocation } from 'react-router-dom'
import { KVKKText } from './KVKKText'

export const Register = () => {
  const classes = useStyles()
  const { state } = useLocation<{ email?: string }>()
  const { register, registerError } = useAuth()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { palette } = useTheme()

  const onSubmit = ({
    email,
    password,
    ageRange,
    definitions,
    communicationPreference,
    phoneNumber,
  }: {
    email: string
    password: string
    ageRange: string
    definitions: DropdownOption[]
    communicationPreference: DropdownOption[]
    phoneNumber: string
    isReadAndAccepted: boolean
  }) => {
    const phone = phoneNumber.trim()
    register({
      email: email.trim(),
      password: password.trim(),
      ageRange,
      communicationPreference: communicationPreference.map(o => o.value),
      definitions: definitions.map(o => o.value),
      phoneNumber: phone || null,
    })
  }

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email('Geçersiz email.')
      .required('Geçersiz e-posta adresi.'),
    password: Yup.string()
      .required('Lütfen şifre gir.')
      .min(4, 'Şifre çok kısa.')
      .max(50, 'Şifre çok uzun.'),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Şifreler aynı olmalı'
    ),
    isReadAndAccepted: Yup.boolean().oneOf(
      [true],
      "KVKK Aydınlatma Metni'ni okuyup, kabul etmelisin."
    ),
  })

  const phoneNumberNeeded = (commPref: DropdownOption[]) =>
    commPref.some(p =>
      ['MESAJ', 'SESLI_ARAMA', 'GORUNTULU_GORUSME'].includes(p.value)
    )

  const layoutContent = (
    <Fragment>
      <Formik
        initialValues={{
          email: state?.email || '',
          password: '',
          passwordConfirmation: '',
          ageRange: '',
          definitions: [],
          communicationPreference: [],
          phoneNumber: '',
          isReadAndAccepted: false,
        }}
        onSubmit={onSubmit}
        validationSchema={RegisterSchema}>
        {({ handleSubmit, values, setFieldValue, errors }) => (
          <Form>
            <Dialog
              open={!!isDialogOpen}
              onClose={() => setIsDialogOpen(false)}
              fullWidth
              maxWidth='lg'>
              <DialogContent className={classes.dialogContent}>
                <KVKKText />
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <MuiButton onClick={() => setIsDialogOpen(false)}>
                  İptal
                </MuiButton>
                <MuiButton
                  onClick={() => {
                    setFieldValue('isReadAndAccepted', true)
                    setIsDialogOpen(false)
                  }}>
                  KABUL EDİYORUM
                </MuiButton>
              </DialogActions>
              <Space size={1} />
            </Dialog>
            <Align vertical>
              {registerError && <Alert level='error'>{registerError}</Alert>}
              <Typography align='center' className={classes.contentTitle}>
                Kayıt ol
              </Typography>
              <Space />
              <Input name='email' label='E-posta adresi' type='email' />
              <Space />
              <Dropdown
                name='ageRange'
                options={AGE_RANGES.map(r => ({ label: r, value: r }))}
                label='Yaş aralığı'
              />
              <Space />
              <Dropdown
                name='definitions'
                options={DEFINITION_OPTIONS}
                label='Seni en iyi tanımlayan seçenekler hangileri? (Birden fazla seçebilirsin.) '
                multi
              />
              <Space />
              <Dropdown
                name='communicationPreference'
                options={CONTACT_OPTIONS}
                label='İletişim tercihi'
                multi
              />
              {phoneNumberNeeded(values.communicationPreference) && (
                <Fragment>
                  <Space />
                  <Input name='phoneNumber' label='Telefon numarası' />
                </Fragment>
              )}
              <Space />
              <Input
                name='password'
                label='Şifre'
                type={showPassword ? 'text' : 'password'}
                icon={
                  <Box
                    className={classes.visibilityIcon}
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={e => e.preventDefault()}>
                    {showPassword ? (
                      <MdVisibility color={palette.primary.main} />
                    ) : (
                      <MdVisibilityOff color={palette.primary.main} />
                    )}
                  </Box>
                }
                iconPosition='end'
              />
              <Space />
              <Input
                name='passwordConfirmation'
                label='Şifre tekrar'
                type={showConfirmedPassword ? 'text' : 'password'}
                icon={
                  <Box
                    className={classes.visibilityIcon}
                    onClick={() =>
                      setShowConfirmedPassword(!showConfirmedPassword)
                    }
                    onMouseDown={e => e.preventDefault()}>
                    {showConfirmedPassword ? (
                      <MdVisibility color={palette.primary.main} />
                    ) : (
                      <MdVisibilityOff color={palette.primary.main} />
                    )}
                  </Box>
                }
                iconPosition='end'
              />
              <Align align='center'>
                <Checkbox
                  name='isReadAndAccepted'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  className={classes.checkbox}
                  classes={{ root: classes.root }}
                  checked={values.isReadAndAccepted}
                  onChange={() =>
                    setFieldValue(
                      'isReadAndAccepted',
                      !values.isReadAndAccepted
                    )
                  }
                />
                <Typography variant='body2'>
                  <Box
                    component='span'
                    className={classes.text}
                    onClick={() => setIsDialogOpen(true)}>
                    KVKK Aydınlatma Metni
                  </Box>
                  'ni okudum, kabul ediyorum.
                </Typography>
              </Align>
              <Typography className={classes.checkboxError}>
                {errors?.isReadAndAccepted}
              </Typography>
              <Space size={2} />
              <Button
                variant='secondary'
                onClick={handleSubmit}
                text='Kaydı tamamla'
              />
            </Align>
          </Form>
        )}
      </Formik>
    </Fragment>
  )

  return (
    <Page>
      <Box className={classes.containerBox}>
        <AppLayout styles={{ height: '100%' }}>
          <Align justify='center' height={100}>
            <LayoutPaper backBtnText='Geri dön'>{layoutContent}</LayoutPaper>
          </Align>
        </AppLayout>
      </Box>
    </Page>
  )
}
