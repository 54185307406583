import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Paper,
  Typography,
} from '@material-ui/core'
import { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'

import { useHistory, useParams } from 'react-router'
import { Align } from '../../common/components/Align'
import { Page } from '../../common/components/Page'
import useStyles from './styles'
import { useGetRequest } from '../../common/useGetRequest'
import { PRACTICAL_INFO_CATEGORIES } from '../../types/domain'
import { AppLayout } from '../../common/components/Layout'
import { VideoPlayer } from '../Video/VideoPlayer/VideoPlayer'
import { Space } from '../../common/components/Space'
import { initializeGA } from '../../utils/googleAnalytics'

export const Information = () => {
  initializeGA('PratikBilgiler')
  const classes = useStyles()
  const { category } = useParams<{ category: string }>()
  const [selectedCategory, setSelectedCategory] = useState(
    category || PRACTICAL_INFO_CATEGORIES[0].id
  )
  const { data: practicalInfo, loading } = useGetRequest(
    '/practical-information',
    {}
  )
  const history = useHistory()
  const [openVideo, setOpenVideo] = useState<any>()

  const onSelectedCategoryChange = (id: string) => {
    setSelectedCategory(id)
    history.push({ pathname: `/pratik-bilgiler/${id}` })
  }

  return (
    <Page>
      <AppLayout>
        <Dialog
          open={!!openVideo}
          onClose={() => setOpenVideo(null)}
          fullWidth
          maxWidth='lg'
          style={{ padding: 16 }}>
          <Align justify='space-between' align='center' padding={[1]}>
            <Typography variant='h2' className={classes.title}>
              {openVideo?.title}
            </Typography>
            <AiOutlineClose
              size={20}
              onClick={() => setOpenVideo(null)}
              className={classes.closeIcon}
            />
          </Align>
          <DialogContent>
            <VideoPlayer url={openVideo?.videoUrl} />
          </DialogContent>
          <Space size={1} />
        </Dialog>
        <Box>
          <Typography align='center' variant='h2' gutterBottom>
            Pratik Bilgiler
          </Typography>
          <Typography align='center' gutterBottom>
            Acil durumlarda ihtiyacın olabilecek bazı bilgiler olabilir. Bunlar
            ‘‘acil’’ başlığı altında. Bazı soruların da olabilir. Bunlar ‘‘sık
            sorulan sorular (sss)’’ başlığı altında. İlgili kutuya tıkla ve
            öğren.
          </Typography>
        </Box>
        <Box
          display='flex'
          mt={4}
          mb={4}
          justifyContent='space-between'
          className={classes.containerBox}>
          {PRACTICAL_INFO_CATEGORIES.map(({ id, label, Icon }) => (
            <Box className={classes.boxWrapper} key={id}>
              <Card
                onClick={() => onSelectedCategoryChange(id)}
                className={
                  selectedCategory === id
                    ? classes.selectedCategoryCard
                    : classes.categoryCard
                }>
                <CardContent>
                  <Align vertical align='center'>
                    <Icon
                      className={
                        selectedCategory === id
                          ? classes.selectedCategoryCardIcon
                          : classes.categoryCardIcon
                      }
                    />
                    <Typography>{label}</Typography>
                  </Align>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>

        <Box mb={8}>
          {!loading &&
            (practicalInfo[selectedCategory] || []).map(
              ({ thumbnailUrl, videoUrl, title, content }) => (
                <Paper className={classes.infoCard} key={title}>
                  <Align fullWidth>
                    <img
                      alt={title}
                      src={thumbnailUrl}
                      className={classes.thumbnail}
                      onClick={() => setOpenVideo({ videoUrl, title })}
                    />
                    <Align vertical margin={[0, 1.5]}>
                      <Typography className={classes.infoCardTitle}>
                        {title}
                      </Typography>
                      <Typography>{content}</Typography>
                    </Align>
                  </Align>
                </Paper>
              )
            )}
        </Box>
      </AppLayout>
    </Page>
  )
}
