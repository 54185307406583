import { useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { useParams, useHistory } from 'react-router'
import classNames from 'classnames'

import { QuestionChoice } from '../QuestionChoice/QuestionChoice'
import { CompletedAnswer, Question as IQuestion, QuestionType } from '../../../types/domain'

import useStyles from './styles'
import { VideoPlayer } from '../VideoPlayer/VideoPlayer'
import { Align } from '../../../common/components/Align'
import { Button } from '../../../common/components/Button'
import { useVideoSectionContext } from '../context/VideoSectionContext'
import { makeRequest } from '../../../common/makeRequest'
import { SectionEnd } from '../SectionEnd/SectionEnd'

interface Props {
  questions: IQuestion[]
  getAreQnsAlreadyAnswered: (type: QuestionType) => boolean
  isInitialForm?: boolean
  nextVideoId?: string | undefined
  isLastVideo?: boolean
}

type AnswerType = { [questionId: string]: string }

export const QuestionsForm = ({
  questions,
  nextVideoId,
  getAreQnsAlreadyAnswered,
  isInitialForm = false,
  isLastVideo = false,
}: Props) => {
  const classes = useStyles()
  const history = useHistory()

  const { id: videoId } = useParams<{ id: string }>()
  const [questionNum, setQuestionNum] = useState(1)

  // answers to be sent
  const [userAnswers, setUserAnswers] = useState<AnswerType>({})
  const [correctAnswers, setCorrectAnswers] = useState<AnswerType>({})

  // user input state, can be changed
  const [selectedChoices, setSelectedChoices] = useState<AnswerType>({})

  const { onVideoSectionSet } = useVideoSectionContext()

  const answerQuestion = (questionId: string) => {
    const answer = selectedChoices[questionId]

    // to dissallow reanswering final tests
    if (answer && !userAnswers[questionId]) {
      setUserAnswers({
        ...userAnswers,
        [questionId]: answer,
      })

      makeRequest({
        url: `/videos/${videoId}/questions/${questionId}/answer`,
        method: 'POST',
        payload: { answer },
        onSuccess: (data: CompletedAnswer) => {
          setCorrectAnswers({
            ...correctAnswers,
            [questionId]: data.correctAnswer,
          })
        },
        onError: e => console.error('Error ocurred while answering the question: ', e),
      })
    }
  }

  const onAnswerQuestionClick = (questionId: string) => {
    if (!isInitialForm) {
      answerQuestion(questionId)
    } else {
      setUserAnswers({
        ...userAnswers,
        [questionId]: selectedChoices[questionId],
      })
      setQuestionNum(questionNum + 1)
    }
  }

  const completeQuestions = (answers: AnswerType, type: QuestionType) => {
    const areQuestionsAlreadyAnswered = getAreQnsAlreadyAnswered(type)

    if (!areQuestionsAlreadyAnswered) {
      const modifiedAnswersObject = Object.keys(answers).reduce((acc, key) => ({ ...acc, [key]: [answers[key]] }), {})

      return makeRequest({
        url: `/videos/${videoId}/questions/complete`,
        method: 'POST',
        payload: { answers: modifiedAnswersObject, type },
        onSuccess: () => {
          setQuestionNum(questionNum + 1)
        },
        onError: e => console.error('Error ocurred while answering the question: ', e),
      })
    }

    setQuestionNum(questionNum + 1)
  }

  const onCompleteQuestionsClick = (questionId: string, type: QuestionType) => {
    if (!isInitialForm) {
      completeQuestions(userAnswers, type)
    }

    if (isInitialForm && !userAnswers[questionId]) {
      const answer = selectedChoices[questionId]

      const questionAnswers = {
        ...userAnswers,
        [questionId]: answer,
      }

      setUserAnswers(questionAnswers)
      completeQuestions(questionAnswers, type)
    }
  }

  const onGoNextSectionOrVideoClick = () => {
    if (isInitialForm) {
      onVideoSectionSet(2)
    } else if (nextVideoId) {
      // Send to the next part/video
      history.push({ pathname: `/video/${nextVideoId}`, search: '?section=1' })
      onVideoSectionSet(1)
      setQuestionNum(1)
      // Clean user input
      setSelectedChoices({})
      setUserAnswers({})
      setCorrectAnswers({})
    }
  }

  return (
    <Box>
      <Box className={classes.questionsContainer}>
        {questions.map(
          ({ id, videoUrl, text, choices, type }, i) =>
            i + 1 === questionNum && (
              <Box key={id}>
                <Grid container>
                  <Grid item xs={12} lg={7} md={7}>
                    <VideoPlayer url={videoUrl} />
                  </Grid>
                  <Grid item xs={12} lg={5} md={5}>
                    <Box className={classes.questionBox}>
                      <Typography variant='h4'>SORU {questionNum}</Typography>
                      <div>{text}</div>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container className={classes.choiceGridContainer}>
                  {choices.map((choice, idx) => (
                    <Grid
                      item
                      key={choice.id}
                      xs={12}
                      md={6}
                      lg={6}
                      className={classNames(classes.choiceItem, {
                        [classes.firstCol]: idx % 2 === 0,
                        [classes.lastCol]: idx % 2 !== 0,
                      })}>
                      <QuestionChoice
                        choice={choice}
                        userAnswer={userAnswers[id]}
                        correctAnswer={correctAnswers[id]}
                        selectedChoice={selectedChoices[id]}
                        isInitialForm={isInitialForm}
                        setSelectedChoice={label =>
                          setSelectedChoices({
                            ...selectedChoices,
                            [id]: label,
                          })
                        }
                      />
                    </Grid>
                  ))}
                </Grid>

                <Align justify='space-between' style={{ paddingLeft: 36 }}>
                  <Button
                    text='Geri'
                    variant='tertiary'
                    style={{ visibility: i === 0 && 'hidden' }}
                    onClick={() => i !== 0 && setQuestionNum(i)}
                  />

                  {questionNum === questions.length &&
                  ((isInitialForm && selectedChoices[id]) || (!isInitialForm && userAnswers[id])) ? (
                    <Button text={'Kaydet ve bitir'} onClick={() => onCompleteQuestionsClick(id, type)} />
                  ) : !isInitialForm && userAnswers[id] ? (
                    <Button
                      text='İleri'
                      isDisabled={!selectedChoices[id]}
                      onClick={() => setQuestionNum(questionNum + 1)}
                    />
                  ) : (
                    <Button
                      text={'Cevapla'}
                      isDisabled={!selectedChoices[id]}
                      onClick={() => onAnswerQuestionClick(id)}
                    />
                  )}
                </Align>
              </Box>
            )
        )}
      </Box>
      {questionNum > questions.length && (
        <SectionEnd
          section={isInitialForm ? 'pre' : 'post'}
          isInitialForm={isInitialForm}
          isLastVideo={isLastVideo}
          sectionEndText={
            isInitialForm
              ? 'Anket sorularına cevap verdin, bize destek oldun. Teşekkür ederiz. Şimdi videoya geçebilirsin.'
              : 'Alıştırma sorularına cevap verdin, teşekkür ederiz.'
          }
          onGoNextClick={onGoNextSectionOrVideoClick}
        />
      )}
    </Box>
  )
}
