import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ spacing, breakpoints }) =>
  createStyles({
    certificatePaper: {
      padding: spacing(5, 2),
      margin: '16px 0',
      boxShadow: '0 3px 9px 0 rgba(21,27,38,.2)',
    },
    leftCol: {
      [breakpoints.up('sm')]: {
        paddingRight: spacing(2),
      },
    },
    inputWrapper: {
      marginTop: spacing(7),
    },
  })
)
