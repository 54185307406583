import { createStyles, makeStyles } from '@material-ui/core'

import backgroundImage from '../../assets/about-page-carousel.png'

export default makeStyles(({ spacing, breakpoints }) =>
  createStyles({
    containerBox: {
      height: '100%',
      background: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${backgroundImage}) no-repeat center`,
      backgroundSize: 'cover',
      padding: spacing(7, 0),
    },
    contentTitle: {
      paddingBottom: spacing(1),
      fontWeight: 'bold',
    },
    loginActions: {
      display: 'flex',
      flexDirection: 'column',

      [breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    infoText: {
      marginBottom: spacing(6),
    },
  })
)
