import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ spacing, palette }) =>
  createStyles({
    videoActionsWrapper: {
      padding: spacing(0, 3.75),
    },
    viewAllText: {
      textDecoration: 'underline',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    playIcon: {
      color: palette.primary.main,
      fontSize: 22,
      cursor: 'pointer',
    },
    continueText: {
      textDecoration: 'underline',
      color: palette.primary.main,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  })
)
