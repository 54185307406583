import { createStyles, makeStyles } from '@material-ui/core'

import backgroundImage from '../../assets/about-page-carousel.png'

export default makeStyles(({ spacing, palette, breakpoints }) =>
  createStyles({
    containerBox: {
      background: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${backgroundImage}) no-repeat center`,
      backgroundSize: 'cover',
      padding: spacing(4, 0),
      [breakpoints.up('sm')]: {
        padding: spacing(20, 0),
      },
    },
    infoSection: {
      background: `linear-gradient(${palette.primary.light} 65%, transparent 40%) no-repeat`,
      padding: spacing(5, 0, 6),

      [breakpoints.up('sm')]: {
        paddingBottom: spacing(8),
      },
    },
    title: {
      textAlign: 'center',
    },
    infoText: {
      padding: spacing(1),

      [breakpoints.up('md')]: {
        padding: spacing(2),
      },
    },
    teamSection: {
      paddingBottom: spacing(3),

      [breakpoints.up('sm')]: {
        paddingBottom: spacing(15),
      },
    },
    sectionTitleBar: {
      backgroundColor: palette.primary.light,
    },
    infoSectionImg: {
      width: '100%',
      height: 'auto',

      [breakpoints.up('sm')]: {
        width: 300,
      },
    },
    introTitle: {
      borderRadius: 50,
      backgroundColor: palette.white.main,
      padding: spacing(1.5, 2.4),
      fontWeight: 'bold',
      fontSize: 16,
    },
    personContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: spacing(4),
    },
    avatar: {
      minWidth: 180,
      height: 180,
      textAlign: 'center',
      marginBottom: spacing(2.5),
    },
    person: {
      '& span': {
        fontSize: 16,
        fontWeight: 'bold',
      },
    },
    role: {
      fontSize: 14,
      paddingTop: spacing(1),
      //@ts-ignore
      fontWeight: 'normal !important',
      color: palette.primary.main,
    },
  })
)
