import { createStyles, makeStyles, Typography } from '@material-ui/core'
import { Align } from '../Align'

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    noDisplayMsg: {
      color: palette.gray.main,
    },
  })
)

export const NoData = () => {
  const classes = useStyles()

  return (
    <Align align='center' justify='center' padding={[2]}>
      <Typography className={classes.noDisplayMsg}>
        Gösterilecek bir bilgi yok
      </Typography>
    </Align>
  )
}
