import {
  Button,
  Card,
  CardContent,
  createStyles,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { FC } from 'react'
import { Align } from '../../../../../common/components/Align'
import { Space } from '../../../../../common/components/Space'
import { VideoUrlInput } from '../../../../../common/components/VideoUrlInput'
import { Choice, Question } from '../../../../../types/domain'
import { useCourseContext } from '../../context'

export const QuestionsDetail: FC<{}> = () => {
  const classes = useStyles()
  const {
    selectedVideo,
    handleAddChoiceClick,
    handleAddQuestionClick,
    updateChoiceProperty,
    updateQuestionProperty,
    saveVideo,
    saveEnabled,
    videoUrlValidationResults,
    deleteChoice,
  } = useCourseContext()

  const renderChoices = (questionId: string, questionIdx: number, choices: Choice[]) => {
    return choices.map((c, choiceIdx) => (
      <Card style={{ margin: 8 }}>
        <CardContent>
          <Align>
            <Align align='center' margin={[0, 2, 0, 1]}>
              <Select
                value={c.label}
                variant='outlined'
                onChange={({ target }) => updateChoiceProperty(questionId, c.id, 'label', target.value)}>
                {['A', 'B', 'C', 'D'].map(a => (
                  <MenuItem value={a}>{a}</MenuItem>
                ))}
              </Select>
            </Align>
            <Align vertical style={{ width: '85%' }}>
              <TextField
                variant='outlined'
                value={c.text}
                label='Text'
                fullWidth
                size='small'
                onChange={({ target }) => updateChoiceProperty(questionId, c.id, 'text', target.value)}
              />
              <Space size={2} />
              <VideoUrlInput
                value={c.videoUrl}
                label='VideoUrl'
                setValue={v => updateChoiceProperty(questionId, c.id, 'videoUrl', v)}
                error={videoUrlValidationResults[`questions[${questionIdx}].choices[${choiceIdx}].videoUrl`]}
              />
            </Align>
            <Align align='center' padding={[0, 0, 0, 1]}>
              <IconButton disabled={!c.isNew} onClick={() => deleteChoice(questionId, c.id)}>
                <Delete />
              </IconButton>
            </Align>
          </Align>
        </CardContent>
      </Card>
    ))
  }

  const renderQuestions = (questions: Question[]) => {
    return questions.map(({ id, order, text, videoUrl, answer, choices }, questionIdx) => (
      <Card className={classes.root}>
        <CardContent>
          <Align>
            <Align align='center'>
              <Typography style={{ marginRight: 16 }}>Cevap</Typography>
              <Select
                value={answer}
                variant='outlined'
                onChange={({ target }) => updateQuestionProperty(id, 'answer', target.value)}>
                {['A', 'B', 'C', 'D'].map(a => (
                  <MenuItem value={a}>{a}</MenuItem>
                ))}
              </Select>
            </Align>
          </Align>
          <Space size={2} />
          <TextField
            variant='outlined'
            value={order}
            label='Pozisyon'
            fullWidth
            size='small'
            onChange={({ target }) => updateQuestionProperty(id, 'order', parseInt(target.value))}
          />
          <Space size={2} />
          <TextField
            variant='outlined'
            value={text}
            label='Text'
            fullWidth
            size='small'
            onChange={({ target }) => updateQuestionProperty(id, 'text', target.value)}
          />
          <Space size={2} />
          <VideoUrlInput
            value={videoUrl}
            label='VideoUrl'
            setValue={v => updateQuestionProperty(id, 'videoUrl', v)}
            error={videoUrlValidationResults[`questions[${questionIdx}].videoUrl`]}
          />
          <Space size={1} />
        </CardContent>
        <Align
          padding={[1, 1, 1, 2]}
          margin={[0, 0.5]}
          style={{ background: '#e2e2e2' }}
          align='center'
          justify='space-between'>
          <Typography variant='body2'>Şıklar</Typography>
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleAddChoiceClick(id)}
            disabled={choices.length >= 4}>
            Şık Ekle
          </Button>
        </Align>
        {renderChoices(id, questionIdx, choices)}
      </Card>
    ))
  }

  return (
    <>
      <Align margin={[0, 0, 1, 0]} align='center' justify='flex-end'>
        <Button variant='contained' style={{ marginRight: 8 }} onClick={() => handleAddQuestionClick('pre')}>
          On Soru Ekle
        </Button>
        <Button variant='contained' style={{ marginRight: 8 }} onClick={() => handleAddQuestionClick('post')}>
          Son Soru Ekle
        </Button>
        <Button variant='contained' color='primary' onClick={saveVideo} disabled={!saveEnabled}>
          Kaydet
        </Button>
      </Align>
      <Align vertical className={classes.questionContainer}>
        <Typography variant='h6' gutterBottom>
          Ön Sorular
        </Typography>
        {renderQuestions(selectedVideo.questions?.filter(q => q.type === 'pre'))}
      </Align>
      <Align vertical className={classes.questionContainer}>
        <Typography variant='h6' gutterBottom>
          Son Sorular
        </Typography>
        {renderQuestions(selectedVideo.questions.filter(q => q.type === 'post'))}
      </Align>
      <Align align='center' justify='flex-end'>
        <Button variant='contained' color='primary' onClick={saveVideo} disabled={!saveEnabled}>
          Kaydet
        </Button>
      </Align>
    </>
  )
}

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    root: {
      background: '#f7f7f7',
      marginBottom: spacing(2),
      border: '1px solid #7d7d7d',
    },
    questionContainer: {
      border: '1px solid grey',
      borderRadius: 16,
      padding: spacing(2, 3),
      marginBottom: 24,
    },
  })
)
