import { FC } from 'react'
import { createStyles, Link as MuiLink, makeStyles, Typography } from '@material-ui/core'

interface Props {
  path?: string
  onClick?: () => void
}

export const Link: FC<Props> = ({ path, onClick, children }) => {
  const classes = useStyles()
  return (
    <MuiLink href={path} onClick={onClick}>
      <Typography className={classes.text}>{children}</Typography>
    </MuiLink>
  )
}

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    text: {
      color: palette.secondary.light,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
)
