import React, { useState } from 'react'
import {
  Drawer,
  AppBar,
  Hidden,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Collapse,
  CircularProgress,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import styles from './styles'
import { ADMIN_MENU_ITEMS, SubMenuItem } from './menuItemList'
import { useAuth } from '../../contexts/auth'
import { useHistory } from 'react-router'
import { Align } from '../Align'
import { Link } from '../Link'
import { useGetRequest } from '../../useGetRequest'

interface Props {
  isLoading?: boolean
  topBar?: JSX.Element
  mainContent: JSX.Element
  detailOpen?: boolean
  detailComponent?: JSX.Element
}

export const AdminPage: React.FC<Props> = ({
  isLoading,
  topBar,
  mainContent,
  detailComponent,
  detailOpen,
}) => {
  const classes = styles()
  const history = useHistory()
  const { logout } = useAuth()
  const [openedItems, setOpenedItems] = useState<{ [key: string]: boolean }>({})
  const [mobileOpen, setMobileOpen] = useState<boolean>(false)
  const { data } = useGetRequest<{ courses: any[] }>('/admin/courses', {
    courses: [],
  })

  const toggleOpenedItems = (item: string): void => {
    setOpenedItems({ ...openedItems, [item]: !openedItems[item] })
  }

  const handleMobileOpenToggle = (): void => {
    setMobileOpen(!mobileOpen)
  }

  const handleCloseMenu = (): void => {
    setMobileOpen(false)
  }

  const renderSubMenuItems = (subMenuItems: SubMenuItem[], item: string) =>
    subMenuItems.map(({ subLabel, onClick, Icon }, i) => (
      <div
        key={subLabel}
        onClick={() => {
          onClick()
          handleCloseMenu()
        }}>
        <Collapse in={openedItems[item]} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem button className={classes.subMenuItems}>
              <ListItemIcon className={classes.subMenuIcons}>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={subLabel} />
            </ListItem>
          </List>
        </Collapse>
      </div>
    ))

  const menuItems = ADMIN_MENU_ITEMS(history, logout, data.courses)

  const drawer = (
    <List className={classes.drawerListItems}>
      <ListItem>
        <div className={classes.logoWrapper}>
          {/* <img className={classes.logoImg} src={logo} alt='logo' /> */}
        </div>
      </ListItem>
      <Divider className={classes.divider} />
      {menuItems.map(({ label, item, onClick, subMenuItems, Icon }, i) => {
        if (subMenuItems) {
          return (
            <div key={label}>
              <ListItem button onClick={() => toggleOpenedItems(item)}>
                <IconButton className={classes.drawerIcon}>
                  <Icon />
                </IconButton>
                <ListItemText
                  className={classes.drawerItemText}
                  inset
                  primary={label}
                />
                {openedItems[item] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              {renderSubMenuItems(subMenuItems, item)}
            </div>
          )
        }
        return (
          <div key={label}>
            {i === menuItems.length - 1 && (
              <Divider className={classes.divider} />
            )}
            <ListItem onClick={onClick} key={label} button>
              <IconButton className={classes.drawerIcon}>
                <Icon />
              </IconButton>
              <ListItemText
                className={classes.drawerItemText}
                inset
                primary={label}
              />
            </ListItem>
          </div>
        )
      })}
    </List>
  )

  return (
    <div className={classes.drawerRoot}>
      <AppBar classes={{ root: classes.appBar }}>
        <Align align='center' padding={[0.5, 2]} justify='space-between'>
          <IconButton
            className={classes.menuButton}
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={handleMobileOpenToggle}>
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
          <Link onClick={() => history.push('/')}>Siteye Dön</Link>
        </Align>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden xlUp implementation='css'>
          <Drawer
            variant='temporary'
            open={mobileOpen}
            onClose={handleMobileOpenToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            classes={{
              paper: classes.drawerPaper,
            }}>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden lgDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant='permanent'
            open>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        {topBar}
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Align justify='space-between'>
            <Align
              width={detailOpen ? 35 : 100}
              fullWidth={!detailOpen}
              style={detailOpen ? { maxHeight: '88vh', overflow: 'auto' } : {}}>
              {mainContent}
            </Align>
            {detailOpen && (
              <Align fullWidth style={{ height: '88vh' }}>
                {detailComponent}
              </Align>
            )}
          </Align>
        )}
      </main>
    </div>
  )
}
