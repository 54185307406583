import { AppBar, IconButton, Paper, Tab, Tabs, Toolbar, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useState } from 'react'
import { useCourseContext } from '../../context'
import { QuestionsDetail } from './QuestionsDetail'
import useStyles from './styles'
import { VideoDetail } from './VideoDetail'

export const SelectedVideo = () => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)
  const { selectedVideo, setSelectedVideo } = useCourseContext()

  const TABS = [
    {
      label: 'Video',
      Component: <VideoDetail />,
    },
    { label: 'Sorular', Component: <QuestionsDetail /> },
  ]

  return (
    <Paper className={classes.root}>
      <AppBar position='static' color='primary' className={classes.appBar}>
        <Toolbar>
          <Typography variant='h6' className={classes.title}>
            {selectedVideo?.title}
          </Typography>
          <IconButton edge='end' color='inherit' onClick={() => setSelectedVideo(null)} aria-label='menu'>
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Tabs value={selectedTab} onChange={(_, i) => setSelectedTab(i)} style={{ marginLeft: 8 }}>
        {TABS.map(({ label }, i) => (
          <Tab label={label} key={label + i} />
        ))}
      </Tabs>
      <section className={classes.content}>{TABS[selectedTab].Component}</section>
    </Paper>
  )
}
