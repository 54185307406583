import { Box } from '@material-ui/core'
import styled from 'styled-components'

import useScreenWidth from '../../useScreenWidth'
import { Footer } from './Footer'
import { DesktopHeader } from './Header/DesktopHeader'
import { MobileHeader } from './Header/MobileHeader'

const ContentWrapper = styled.div`
  /** Old ios has bug on flex-direction: column collapse: 
  https://stackoverflow.com/questions/32689686/overlapping-css-flexbox-items-in-safari 
  */
  flex: 1 0 auto;
  background-color: white;

  & > * {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

const StyledPageContainer = styled(Box)`
  height: 100%;
`

export const Page = ({ children }) => {
  const { isSmallScreen } = useScreenWidth()

  return (
    <StyledPageContainer
      display='flex'
      justifyContent='space-between'
      flexDirection='column'>
      {/** For the mobile menu, we display side drawer which wraps the whole app */}
      {/** Therefore, we need this check for desktop here */}
      {!isSmallScreen && <DesktopHeader />}
      <ContentWrapper>
        <MobileHeader>{children}</MobileHeader>
      </ContentWrapper>
      <Footer />
    </StyledPageContainer>
  )
}
