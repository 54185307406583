import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ breakpoints, spacing }) =>
  createStyles({
    previewColumnContainer: {
      height: '100%',
      display: 'flex',
      marginTop: spacing(2),

      [breakpoints.up('lg')]: {
        flexDirection: 'column',
        marginTop: 0,
        paddingLeft: spacing(5),
      },
    },
    carouselWrapper: {
      // Subtract -10px from sides to make it aligned
      margin: '15px -10px 20px',
    },
  })
)
