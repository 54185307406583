import { createStyles, makeStyles } from '@material-ui/core'

interface StyleProps {
  wrongAnswer: boolean
  correctAnswer: boolean
}

export default makeStyles(({ spacing, palette }) =>
  createStyles({
    choiceContainer: {
      display: 'flex',
    },
    label: {
      fontWeight: 'bold',
      margin: '0 14px 10px 2px',
    },
    checkbox: {
      background: 'transparent',
      marginRight: spacing(1.5),
    },
    root: {
      padding: 0,
    },
    checked: {}, // this is required to override the checked styles
    answerIndicator: {
      width: '100%',
      position: 'absolute',
      top: 0,
      zIndex: '10 !important' as any,
      background: ({ correctAnswer }: StyleProps) =>
        correctAnswer ? palette.success.main : palette.error.main,

      '& > *': {
        color: palette.white.main,
        padding: spacing(0.5, 0),
      },
    },
  })
)
