import { Box, Grid } from '@material-ui/core'

import useStyles from './styles'
import { VideoSectionPreview } from '../VideoSectionPreview/VideoSectionPreview'
import {
  QuestionType,
  Video as IVideo,
  VideoProgress,
} from '../../../types/domain'
import { QuestionsForm } from '../QuestionsForm/QuestionsForm'
import { useVideoSectionContext } from '../context/VideoSectionContext'
import { VideoCarousel } from '../../../common/components/VideoCarousel/VideoCarousel'
import { getCarouselVideos } from '../../video-helpers'
import { VideoPlayer } from '../VideoPlayer/VideoPlayer'
import { SectionEnd } from '../SectionEnd/SectionEnd'

interface Props {
  videos: IVideo[]
  video: IVideo
  videoProgress: VideoProgress[]
  onVideoEnd: () => void
  onVideoCarouselClick: (id: string) => void
  getCanWatch: (id: string) => boolean
  videoIdUserIsAt?: string
  nextVideoId?: string
  isWatchedMap: Map<string, boolean>
  isVideoEnded: boolean
  setIsVideoEnded: (isVideoEnded: boolean) => void
}

export const VideoWithQuestions = ({
  videos,
  video,
  videoProgress,
  videoIdUserIsAt,
  nextVideoId,
  onVideoCarouselClick,
  onVideoEnd,
  getCanWatch,
  isWatchedMap,
  isVideoEnded,
  setIsVideoEnded,
}: Props) => {
  const classes = useStyles()
  const { videoSection } = useVideoSectionContext()

  const { onVideoSectionSet } = useVideoSectionContext()

  const isLastVideo = videos[videos.length - 1].id === video.id

  const getAreQnsAlreadyAnswered = (type: QuestionType) => {
    const matchedProgressVideo = videoProgress.find(v => v.id === video.id)

    const videoQnsLength = video.questions.filter(q => q.type === type)?.length
    const progressQnsLength = matchedProgressVideo?.completedQuestions?.filter(
      q => q.type === type
    )?.length

    return (
      videoQnsLength &&
      progressQnsLength &&
      videoQnsLength === progressQnsLength
    )
  }

  const initialSectionQuestions = video.questions?.filter(q => q.type === 'pre')
  const lastSectionQuestions = video.questions?.filter(q => q.type === 'post')

  const onWatchAgainClick = () => {
    onVideoSectionSet(2)
    setIsVideoEnded(false)
  }

  const videoContent = [
    <QuestionsForm
      isInitialForm
      questions={initialSectionQuestions}
      getAreQnsAlreadyAnswered={getAreQnsAlreadyAnswered}
    />,
    <Box width='100%'>
      {!isVideoEnded ? (
        <VideoPlayer url={video.videoUrl} onEnded={onVideoEnd} />
      ) : (
        <SectionEnd
          section='video'
          videoHasQuestions
          sectionEndText='Videoyu izledin.'
          onGoNextClick={() => onVideoSectionSet(3)}
          onWatchVideoAgainClick={onWatchAgainClick}
        />
      )}
    </Box>,
    <QuestionsForm
      isLastVideo={isLastVideo}
      questions={lastSectionQuestions}
      nextVideoId={nextVideoId}
      getAreQnsAlreadyAnswered={getAreQnsAlreadyAnswered}
    />,
  ]

  return (
    <Grid container justifyContent='space-between'>
      <Grid item xs={12} lg={9}>
        {videoContent[videoSection - 1]}
      </Grid>
      <Grid item xs={12} lg={3} className={classes.previewColumnContainer}>
        <Grid container spacing={1}>
          <Grid item xs={4} lg={12}>
            <VideoSectionPreview section={1} sectionTitle='Anket' />
          </Grid>
          <Grid item xs={4} lg={12}>
            <VideoSectionPreview section={2} sectionTitle={video.title} />
          </Grid>
          <Grid item xs={4} lg={12}>
            <VideoSectionPreview section={3} sectionTitle='Alıştırma' />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Box className={classes.carouselWrapper}>
          <VideoCarousel
            videos={getCarouselVideos(videos, video.id)}
            videoIdUserIsAt={videoIdUserIsAt}
            handleClick={onVideoCarouselClick}
            getCanWatch={id => getCanWatch(id)}
            isWatchedMap={isWatchedMap}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
