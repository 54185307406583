import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(({ spacing, palette, breakpoints }) =>
  createStyles({
    nav: {
      border: `1px solid ${palette.gray.light}`,
    },
    userIcon: {
      background: palette.primary.main,
      color: palette.white.main,
      fontSize: spacing(15),
      width: spacing(20),
      height: spacing(20),

      '&>span': {
        marginTop: spacing(2),
      },
    },
    gridContainer: {
      margin: spacing(3, 0, 6),
    },
    tabContainer: {
      marginTop: spacing(6),

      [breakpoints.up('md')]: {
        margin: spacing(0, 0, 0, 3.5),
      },
    },
  })
)
